import { Pax, ResponseSuppliment } from '../interfaces/Book';

export interface Suppliment {
  name: string;
  price: number;
  checked: boolean;
  radioValue?: string;
  genId: number;
  ageFrom: number;
  ageTo: number;
  supplimentID: string;
  type: string;
  require: number;
  include: number;
  shift: number;
  duration: number;
  remark: string;
  nameLoc: string;
  applyCode: string;
  supplier: string;
  supplierCC: string;
  service: string;
  isTranserService: number;
}

export class PaxModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  typeDescription: string;
  paxCode: number;
  typeCode: number;
  additionalInfo: string;
  tz: string;

  birthDate: Date | null;
  passportNumber: string;
  passportExpirationDate: Date | null;
  nationality: string;
  address: string;
  fathersName: string;
  mothersName: string;
  birthCountry: string;

  suppliments: Suppliment[];

  constructor(pax: Pax) {
    this.firstName = pax.FirstName || '';
    this.lastName = pax.LastName || '';
    this.email = pax.Email || '';
    this.phone = pax.Phone || '';
    this.typeDescription = pax.PaxTypeDescription;
    this.paxCode = pax.PaxCode;
    this.typeCode = pax.PaxTypeCode;
    this.additionalInfo = pax.AdditionalInfo;
    this.tz = '';

    this.birthDate = null;
    this.passportNumber = '';
    this.passportExpirationDate = null;
    this.nationality = '';
    this.address = '';
    this.fathersName = '';
    this.mothersName = '';
    this.birthCountry = '';

    this.suppliments = [];

    const addSuppliments = (suppliments: ResponseSuppliment[]) => {
      if (suppliments?.length) {
        this.suppliments = suppliments.map((sup) => ({
          name: sup.Name,
          price: sup.Price,
          checked: false,
          genId: sup.GenId,
          ageFrom: sup.AgeFrom,
          ageTo: sup.AgeTo,
          supplimentID: sup.SupplimentID,
          type: sup.Type,
          require: sup.Require,
          include: sup.Include,
          shift: sup.Shift,
          duration: sup.Duration,
          remark: sup.Remark,
          nameLoc: sup.NameLoc,
          applyCode: sup.ApplyCode,
          supplier: sup.Supplier,
          supplierCC: sup.SupplierCC,
          service: sup.Service,
          isTranserService: sup.IsTranserService,
        }));
      }
    };

    addSuppliments(pax.AdultSupplimentsFull);
    addSuppliments(pax.ChildSupplimentsFull);
  }
}
