import { FC, useEffect, useState } from 'react';
import { Autocomplete, Rating, TextField } from '@mui/material';
import { Slider, Tooltip } from '@mui/material';
import Checkbox from '../Ui/Checkbox';
import {
  FilterModel,
  FilterNights,
  FilterPackage,
  FilterRating,
} from '../../ts/classes/FilterModel';
import { useParams } from 'react-router-dom';
import { formatPrice } from '../../services/utilService';
import { FilterMulticomplete } from './FilterMulticomplete';

interface FilterProps {
  filter: FilterModel;
  onChange: (filter: FilterModel) => void;
  onReset: () => void;
}

const Filter: FC<FilterProps> = (props) => {
  const [filter, setFilter] = useState<FilterModel>(props.filter);
  const params = useParams();

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const onChangePrice = (_event: Event, newItem: number[] | number) => {
    const newFilter = {
      ...filter,
      prices: { ...filter.prices, value: newItem as [number, number] },
    };
    setFilter(newFilter);
    props.onChange(newFilter);
  };

  const onPressNights = (value: FilterNights) => {
    const newNights = filter.nights.map((rating) => ({
      ...rating,
      checked: value.count === rating.count ? !rating.checked : rating.checked,
    }));
    const newFilter = { ...filter, nights: newNights };
    setFilter(newFilter);
    props.onChange(newFilter);
  };

  const onPressPackage = (value: FilterPackage) => {
    const newPackages = filter.packages.map((rating) => ({
      ...rating,
      checked: value.name === rating.name ? !rating.checked : rating.checked,
    }));
    const newFilter = { ...filter, packages: newPackages };
    setFilter(newFilter);
    props.onChange(newFilter);
  };

  const onPressRating = (value: FilterRating) => {
    const newRatings = filter.ratings?.map((rating) => ({
      ...rating,
      checked: value.count === rating.count ? !rating.checked : rating.checked,
    }));
    const newFilter = { ...filter, ratings: newRatings };
    setFilter(newFilter);
    props.onChange(newFilter);
  };

  const resetFilters = () => {
    props.onReset();
  };

  const showResetFilter = () => {
    return (
      filter.prices.value[0] !== filter.prices.min ||
      filter.prices.value[1] !== filter.prices.max ||
      filter.nights.some((item) => item.checked) ||
      filter.packages.some((item) => item.checked) ||
      filter.ratings?.some((item) => item.checked) ||
      !!filter.selectedHotel ||
      !!filter.selectedMonths.length ||
      !!filter.selectedCountries.length
    );
  };

  return (
    <div className="filter">
      <FilterMulticomplete
        display={params?.location === 'all' || params?.city === 'all'}
        options={filter.countries}
        value={filter.selectedCountries}
        label="מדינה"
        onChange={(selectedCountries) => props.onChange({ ...filter, selectedCountries })}
      />

      <FilterMulticomplete
        display={params?.month === 'all' || params?.date === 'all'}
        options={filter.months}
        value={filter.selectedMonths}
        label="תאריך"
        onChange={(selectedMonths) => props.onChange({ ...filter, selectedMonths })}
      />

      <div className="filter-item">
        <span className="filter-reset-wr">
          <span className="filter-item-title">טיול</span>
        </span>

        <div className="filter-item-content">
          <div className="full">
            <Autocomplete
              options={filter.hotels}
              onChange={(_: any, value: string | null) =>
                props.onChange({ ...filter, selectedHotel: value as string })
              }
              value={filter.selectedHotel}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
      </div>

      {filter.ratings?.length && (
        <div className="filter-item">
          <span className="filter-item-title">דירוג כוכבים</span>
          <div className="filter-item-content">
            {filter.ratings.map((rating) => (
              <div className="with-price" key={rating.count}>
                <Checkbox
                  onSelect={() => onPressRating(rating)}
                  label=""
                  value={rating.checked}
                  children={<Rating value={rating.count} readOnly />}
                />
                <span className="price">{formatPrice(rating.price, rating.currency)}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="filter-item">
        <span className="filter-item-title">לילות</span>
        <div className="filter-item-content">
          {filter.nights.map((night) => (
            <div className="with-price" key={night.count}>
              <Checkbox
                onSelect={() => onPressNights(night)}
                label={night.count + ' לילות'}
                value={night.checked}
              />
              <span className="price">{formatPrice(night.price, night.currency)}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="filter-item">
        <span className="filter-item-title">מחיר</span>
        <div className="filter-item-content">
          <div className="full">
            <div>
              {formatPrice(filter.prices.value[0])} - {formatPrice(filter.prices.value[1])}
            </div>
            <Slider
              value={filter.prices.value}
              min={filter.prices.min}
              max={filter.prices.max}
              onChange={onChangePrice}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
      </div>

      <div className="filter-item">
        <span className="filter-item-title">חבילות</span>
        <div className="filter-item-content">
          {filter.packages.map((item) => (
            <div className="with-price" key={item.name}>
              <Checkbox
                onSelect={() => onPressPackage(item)}
                label={item.name}
                value={item.checked}
              />
              <span className="price">{formatPrice(item.price, item.currency)}</span>
            </div>
          ))}
        </div>
      </div>

      <button
        className="button-st button-colored w100p"
        onClick={() => document.querySelector('.results-filter')!.classList.remove('open')}
      >
        Свернуть
      </button>
      {showResetFilter() && (
        <Tooltip title="אפס מסננים" arrow>
          <span className="filter-reset" onClick={resetFilters}>
            אִתחוּל
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default Filter;
