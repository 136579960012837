import { FC, useEffect, useState } from 'react';
import { getSkiCities, getSkiDates } from '../../services/searchService';
import { AutocompleteItem } from '../../ts/interfaces/Generic';
import { useNavigate, useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useGetPage } from '../../hooks/useGetPage';

import { faCalendar, faLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchSelect from './SearchSelect';
import { SkiDate, SkiSearchParams } from '../../ts/interfaces/Ski';
import { getObjDate, getStrDate, setButtonClasses } from '../../services/utilService';
import DatePicker from 'react-date-picker';
import { Skeleton } from '@mui/material';

interface SearchSkiProps {
  onSubmit: () => void;
}

export const SearchSki: FC<SearchSkiProps> = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<SkiSearchParams>({
    city: '',
    date: null,
  });
  const [dates, setDates] = useState<SkiDate[]>([]);
  const [cities, setCities] = useState<AutocompleteItem[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams();
  const page = useGetPage();

  useEffect(() => {
    if (
      page?.Page.Country?.Code &&
      cities.some((country) => page?.Page.Country?.Code === country.value)
    ) {
      setSearchParams({ ...searchParams, city: page.Page.Country.Code });
      getDatesByLocation(page.Page.Country.Code);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const cities = await getSkiCities();
      setCities(cities);
      if (params.city && params.date) {
        const storedSkiDates = sessionStorage.getItem('skiDates');
        const newDates = !storedSkiDates
          ? await getSkiDates(params.city)
          : JSON.parse(storedSkiDates);
        setDates(newDates);
        setSearchParams({
          date: getObjDate(params.date),
          city: params.city,
        });
      } else {
        setSearchParams({ city: cities[0].value, date: null });
        getDatesByLocation(cities[0].value);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDatesByLocation = async (city: string) => {
    const newDates = await getSkiDates(city);
    setDates(newDates);

    setSearchParams({ city, date: getObjDate(newDates[0].Date) });
  };

  const onSelectLocation = async (city: string) => {
    setSearchParams({ date: null, city });
    setDates([]);
    getDatesByLocation(city);
  };

  const onSubmit = async () => {
    if (searchParams.city && searchParams.date && !isDisabled) {
      try {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha('search');
        if (!token) {
          console.log('No token');
          return;
        }
      } catch (err) {}

      navigate(`/ski/results/${searchParams.city}/${getStrDate(searchParams.date)}`);
      props.onSubmit();
      setIsDisabled(true);
      setTimeout(() => setIsDisabled(false), 1000);
    }
  };

  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    const srtDate = getStrDate(date);
    if (view === 'month') {
      return !dates.some((dbDate) => dbDate.Date === srtDate);
    } else if (view === 'year') {
      const startDate = getStrDate(date).slice(0, 7);
      return !dates.some((dbDate) => dbDate.Date.startsWith(startDate));
    } else if (view === 'decade') {
      const year = date.getFullYear();
      return !dates.some((dbDate) => dbDate.Date.startsWith(`${year}`));
    }

    return false;
  };

  const onClickCalendar = (event: any) => {
    if (!event.target.closest('.react-date-picker__calendar')) {
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  return (
    <div className="search-main">
      <SearchSelect
        data={cities}
        value={searchParams.city}
        icon={faLocationDot}
        onSelect={onSelectLocation}
        subText="לאן אתה הולך?"
      />

      <div
        className={
          'search-main-cell search-main-cell-active-last search-main-cell-last ' +
          (isCalendarOpen ? ' search-main-cell-active' : '')
        }
        onClick={onClickCalendar}
      >
        <FontAwesomeIcon icon={faCalendar} className="search-icon" />
        <div className={'search-main-box ' + (!searchParams.date ? 'react-date-picker-empty' : '')}>
          {dates.length ? (
            <DatePicker
              value={searchParams.date}
              isOpen={isCalendarOpen}
              onCalendarClose={() => setTimeout(() => setIsCalendarOpen(false), 100)}
              locale="he-IL"
              format="dd/MM/yyyy"
              clearIcon={null}
              calendarIcon={null}
              minDetail="decade"
              dayPlaceholder=""
              monthPlaceholder=""
              yearPlaceholder=""
              defaultActiveStartDate={getObjDate(dates[0].Date)}
              tileDisabled={tileDisabled}
              onChange={(date) => setSearchParams({ ...searchParams, date: date as Date })}
            />
          ) : (
            <Skeleton variant="rectangular" animation="wave" width={110} height={23} />
          )}

          <div className="search-main-text"> בחר תאריך</div>
        </div>
      </div>

      <div
        className={setButtonClasses(
          'search-main-button',
          !searchParams.city || !searchParams.date || isDisabled,
        )}
        onClick={onSubmit}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
    </div>
  );
};

export default SearchSki;
