import React, { FC, Fragment, useEffect } from 'react';
import { Itinerary } from '../../ts/interfaces/PackageDetails';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ItineraryCardProps {
  itinerary: Itinerary;
}

const ItineraryCard: FC<ItineraryCardProps> = ({ itinerary }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  useEffect(() => {
    const itineraryListHeight = document.querySelector('.itinerary-list')?.clientHeight;
    document
      .querySelector('.itinerary-content')
      ?.setAttribute('style', `height: ${itineraryListHeight}px`);
  }, []);

  const onPressLocation = (index: number) => {
    const content = document.querySelector(`#content${index}`) as HTMLElement;
    document
      .querySelector('.itinerary-content')
      ?.scrollTo({ top: content!.offsetTop, behavior: 'smooth' });
  };

  const onScrollContent = () => {
    const childTopPositons = Array.from(document.getElementsByClassName('itinerary-text')).map(
      (el: any, index) => el.offsetTop,
    );
    const top = document.querySelector('.itinerary-content')?.scrollTop!;

    const newActiveIndex = childTopPositons.findIndex((el) => el > top);

    setActiveIndex(newActiveIndex > 0 ? newActiveIndex - 1 : 0);
  };

  return (
    <Fragment>
      <div className="details-title">
        <span>מידע על טיולים - </span>
        <a className="text-default-color" href={itinerary.ItineraryUrl}>
          מסלול הורדה
        </a>
      </div>

      {!!itinerary.Items?.length && (
        <div className="details-shadow itinerary">
          <div className="itinerary-list">
            {itinerary.Items.map((item, index) => (
              <div
                className={'itinerary-item ' + (activeIndex === index ? 'active' : '')}
                key={index}
                onClick={() => onPressLocation(index)}
              >
                <span className="day">יום {item.Details.Day}</span>
                <span className="item-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                <span className="item-location">{item.ItemDesc}</span>
              </div>
            ))}
          </div>
          <div className="itinerary-content" onScroll={onScrollContent}>
            {itinerary.Items.map((item, index) => (
              <div className="itinerary-text" key={index} id={'content' + index}>
                <div>
                  <div className="text-bold">יום {item.Details.Day}</div>
                  <img src={item.Details.Image.Link} alt={item.Details.Image.Alt} />
                  {item.Details.Description}
                  <div>
                    {item.Details?.Addons.map((addon) => (
                      <Fragment>
                        {addon.DescLang.length ? (
                          <div className="itinerary-desc">
                            <span className="text-bold"> {addon.DescLang[0].Desc} </span>
                            <br />
                            {addon.DescLang[0].ProductDesc}
                            <br />
                            {addon.Tags}
                          </div>
                        ) : (
                          ''
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ItineraryCard;
