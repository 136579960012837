import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react';

const ResultsListHeaderSkeleton = () => {
  useEffect(() => {
    if (localStorage.getItem('shortViewResults')) {
      document.querySelector('.results-list')!.classList.add('short');
    }
  }, []);
  return (
    <div className="results-header">
      <div className="results-title">
        <h2>
          <Skeleton variant="rectangular" animation="wave" height={39} width={'80%'} />
        </h2>
        <div className="flex-vertical-center">
          <Skeleton variant="rectangular" animation="wave" height={16} width={'35%'} />
        </div>
      </div>

      <div className="results-sorting">
        {[...Array(3)].map((_, index) => (
          <div className="results-sorting-item" key={index}>
            <div className="content">
              <Skeleton variant="rectangular" animation="wave" height={20} width={80} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsListHeaderSkeleton;
