import React, { Fragment } from 'react';
import { useGetPage } from '../hooks/useGetPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  const page = useGetPage();

  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="footer-col">
          <img src={require('../assets/images/OphirLogo.png')} alt="Logo" />
        </div>

        {page?.Footer.Languages[0].Title_2.Body && (
          <div
            className="footer-col footer-col-main"
            dangerouslySetInnerHTML={{
              __html: page?.Footer.Languages[0].Title_2.Body.replaceAll('&nbsp;', ''),
            }}
          ></div>
        )}

        <div className="footer-col">
          {page?.cmsSite.Phone1 && (
            <a href={`tel: ${page?.cmsSite.Phone1}`}>
              <FontAwesomeIcon icon={faPhone} />
              {page?.cmsSite.Phone1}
            </a>
          )}
          {page?.cmsSite.Email1 && (
            <a href={`mailto: ${page?.cmsSite.Email1}`}>
              <FontAwesomeIcon icon={faEnvelope} />
              {page?.cmsSite.Email1}
              <p></p>
            </a>
          )}
        </div>

        {page?.Footer.Languages[0].Title_3.Body && (
          <Fragment>
            <div className="footer-col"></div>
            <div
              className="footer-col footer-col-main"
              dangerouslySetInnerHTML={{
                __html: page?.Footer.Languages[0].Title_3.Body.replaceAll('&nbsp;', ''),
              }}
            ></div>
          </Fragment>
        )}
      </div>
      <div className="footer-col-bottom">
        <div className="footer-col-bottom-row">
          {page?.CmsStandartPages?.map((pageCms: any, index: number) => (
            <Link key={index} to={pageCms.Title.URL}>
              {pageCms.Languages[0].Title.Desc}
            </Link>
          ))}
        </div>
        <div>
          מונע ע"י
          <a
            className="be-pro-title"
            href="https://www.beprotravel.com/"
            target="_blank"
            rel="noreferrer"
          >
            BePro Travel
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
