import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { sxDisplayIb } from '../../constants/sx';

const LandingOneSkeleton: FC = () => {
  const row = () => ({ margin: '0 auto 20px' });
  return (
    <div className="main-text">
      <Skeleton variant="rectangular" animation="wave" height={28.5} width={300} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'30%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'40%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'50%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'35%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'80%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'95%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'40%'} sx={row} />
      <Skeleton variant="rectangular" animation="wave" height={26} width={'50%'} sx={row} />
      <div className="single-sale">
        <div className="sales-card-wr">
          <div className="details-title">
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={200}
              height={20}
              sx={sxDisplayIb}
            />
          </div>
          <div className="sales-card">
            <div className="image">
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={'100%'}
                height={'100%'}
                sx={{ borderRadius: '16px' }}
              />
            </div>
            <div className="sales-card-items">
              <Skeleton variant="rectangular" animation="wave" width={'90%'} height={24} />
              <Skeleton variant="rectangular" animation="wave" width={'75%'} height={24} />
              <Skeleton variant="rectangular" animation="wave" width={'78%'} height={24} />
              <Skeleton variant="rectangular" animation="wave" width={'80%'} height={24} />
              <Skeleton variant="rectangular" animation="wave" width={'90%'} height={24} />
            </div>
            <div className="sales-card-footer">
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={35.5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingOneSkeleton;
