import { Validation } from '../ts/interfaces/Validation';

export const passengerValidation: Validation = {
  firstName: { required: true, minLength: 2, maxLength: 20, english: true },
  lastName: { required: true, minLength: 2, maxLength: 20, english: true },
  email: { required: true, email: true },
  phone: { required: true, numbers: true, length: 10 },
  notes: { maxLength: 100 },
};

export const shortPassengerValidation: Validation = {
  firstName: { required: true, minLength: 2, maxLength: 20, english: true },
  lastName: { required: true, minLength: 2, maxLength: 20, english: true },
};

export const firstPassengerValidation: Validation = {
  ...shortPassengerValidation,
  email: { required: true, email: true },
  phone: { required: true, numbers: true, length: 10 },
};

export const detailsPasswordValidation: Validation = {
  password: { required: true },
};

export const getShortExpandedPassengerValidation = (visaRequired: boolean): Validation => ({
  firstName: { required: true, minLength: 2, maxLength: 20, english: true },
  lastName: { required: true, minLength: 2, maxLength: 20, english: true },
  birthDate: { required: true },
  passportNumber: { required: true, numbers: true },
  passportExpirationDate: { required: true },
  mothersName: { required: visaRequired, maxLength: 20 },
  fathersName: { required: visaRequired, maxLength: 20 },
});

export const getFirstExpandedPassengerValidation = (visaRequired: boolean): Validation => ({
  ...getShortExpandedPassengerValidation(visaRequired),
  email: { required: true, email: true },
  phone: { required: true, numbers: true, length: 10 },
  tz: { required: true, numbers: true, length: 9 },
});

export const contactUsValidation: Validation = {
  name: { required: true, minLength: 2, maxLength: 20 },
  phone: { required: true, numbers: true, length: 10 },
  email: { required: true, email: true },
  message: { required: true, minLength: 2, maxLength: 1000 },
};

export const getPassengerSkiValidation = (first: boolean, paxType: number): Validation => {
  const validation: Validation = {
    firstName: { required: true, minLength: 2, maxLength: 20, english: true },
    lastName: { required: true, minLength: 2, maxLength: 20, english: true },
  };

  if (first) {
    validation.email = { required: true, email: true };
    validation.phone = { required: true, numbers: true, length: 10 };
  }

  if (paxType === 2) {
    validation.birthDate = { required: true };
  }

  return validation;
};

export const signInValidation: Validation = {
  username: { required: true, minLength: 2 },
  password: { required: true },
};
