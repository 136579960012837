import React, { useCallback, useState } from 'react';
import { FC } from 'react';
import { ValidationBody } from '../../ts/interfaces/Validation';
import { getErrorInField } from '../../services/validation';

interface FormGroupTextareaProps {
  onChange: (item: string) => void;
  className?: string;
  value: string;
  label: string;
  validation?: ValidationBody;
  isSubmitted?: boolean;
}

const FormGroupTextarea: FC<FormGroupTextareaProps> = (props) => {
  const [value, setValue] = useState(props.value);

  const isValid = useCallback(() => {
    return getErrorInField(props.validation!, value, props.isSubmitted!);
  }, [value, props.isSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.className}>
      <div className="form-group">
        <label>
          {props.label} {props.validation?.required && <span className="danger-color">*</span>}
        </label>
        <textarea
          className={'form-control textarea ' + (isValid() ? 'is-invalid' : '')}
          value={props.value}
          onChange={(event) => {
            setValue(event.target.value);
            props.onChange(event.target.value);
          }}
        />
        {!!isValid() && <div className="form-invalid-text">{isValid()}</div>}
      </div>
    </div>
  );
};

FormGroupTextarea.defaultProps = {
  validation: {},
  isSubmitted: false,
  className: '',
};

export default FormGroupTextarea;
