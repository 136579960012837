import { Skeleton } from '@mui/material';
import React, { Fragment } from 'react';

const ResultsListCardsSkeleton = () => {
  return (
    <Fragment>
      {[...Array(3)].map((_, index) => (
        <div className="results-card" key={index}>
          <div className="results-card-img">
            <Skeleton variant="rectangular" animation="wave" height={'100%'} width={'100%'} />
          </div>
          <div className="results-card-content">
            <div className="results-card-title">
              <Skeleton variant="rectangular" animation="wave" height={29} width={'100%'} />
            </div>
            <div className="results-card-facilities">
              <div className="results-card-facilities-item">
                <Skeleton variant="rectangular" animation="wave" height={16} width={150} />
              </div>
              <div className="results-card-facilities-item">
                <Skeleton variant="rectangular" animation="wave" height={16} width={100} />
              </div>
              <div className="results-card-facilities-item">
                <Skeleton variant="rectangular" animation="wave" height={16} width={100} />
              </div>
            </div>
          </div>
          <div className="results-card-footer">
            <div className="results-card-price">
              <span className="price">
                <Skeleton variant="rectangular" animation="wave" height={21} width={115} />
              </span>
              <span className="price-sm">
                <Skeleton variant="rectangular" animation="wave" height={15} width={92} />
              </span>
            </div>
            <div className="button-wr">
              <Skeleton variant="rounded" animation="wave" height={35.5} width={'100%'} />
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default ResultsListCardsSkeleton;
