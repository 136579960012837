import React, { FC } from 'react';
import { Sale } from '../../ts/interfaces/Sale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHotel, faMoon, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from '../../services/utilService';
import { useNavigate } from 'react-router-dom';
import Gallery from '../Ui/Gallery';

interface SaleCardProps {
  card: Sale;
}

const SaleCard: FC<SaleCardProps> = ({ card }) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    const params = Object.fromEntries(new URLSearchParams(card.SaleUrl.split('?')[1]));
    const checkIn = [
      params.Checkin.split('-')[2],
      params.Checkin.split('-')[1],
      params.Checkin.split('-')[0],
    ].join('-');
    const url = `/details/${card.HotelName}/${params.City}/${checkIn}/${params.Nights}/${params.Id}/${params.PackageId}/${params.Room}`;
    navigate(url);
  };

  return (
    <div className="sales-card-wr">
      <div className="sales-card">
        <Gallery images={card.Images} />
        <div className="image">
          <img src={card.MainImageLink} alt={card.MainImageAlt} />
          <div className="hotel-name">{card.HotelName}</div>
        </div>
        <div className="sales-card-items">
          <div className="item">
            <FontAwesomeIcon icon={faCalendar} />
            {card.CheckIn} - {card.CheckOut}
          </div>
          <div className="item">
            <FontAwesomeIcon icon={faHotel} />
            {card.RoomDescription}
          </div>
          <div className="item">
            <FontAwesomeIcon icon={faUtensils} />
            {card.BoardDescription}
          </div>
          <div className="item">
            <FontAwesomeIcon icon={faMoon} />
            {card.Nights} לילות
          </div>
        </div>
        <div className="sales-card-footer">
          <div className="person-price">
            <span className="title">מחיר לאדם בחדר / </span>
            <span className="price">{formatPrice(card.PricePerPerson, card.CurrencySymbol)}</span>
          </div>
          <div className="total-price">
            <span className="title">סה"כ / </span>
            <span className="price">{formatPrice(card.TotalPrice, card.CurrencySymbol)}</span>
          </div>
          <button className="button-st button-colored" onClick={goToDetails}>
            הזמן
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaleCard;
