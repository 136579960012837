import React, { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDetails, getDetailsParams } from '../services/packageService';
import { PackageDetails } from '../ts/interfaces/PackageDetails';
import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import FlightCard from '../components/Details/FlightCard';
import SummaryCard from '../components/Details/SummaryCard';
import CarouselCard from '../components/Details/CarouselCard';
import InformationCard from '../components/Details/ItineraryCard';
import IncludeCard from '../components/Details/IncludeCard';
import RoomsCard from '../components/Details/RoomsCard';
import DetailsSkeleton from '../components/Skeleton/DetailsSkeleton';
import PasswordForm from '../components/Details/PasswordForm';
import { TokenPackage } from '../ts/interfaces/Package';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const DetailsPage: FC = () => {
  const params = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [details, setDetails] = useState<PackageDetails | undefined>();
  const [detailsParams, setDetailsParams] = useState<getDetailsParams>();

  useEffect(() => {
    (async () => {
      try {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha('detailsSki');
        if (!token) {
          console.log('No token');
          return;
        }
      } catch (err) {}

      const detailsParams: getDetailsParams = {
        checkIn: params.checkIn!,
        city: params.city!,
        dealId: params.dealId!,
        hotelName: params.hotelName!,
        nights: params.nights!,
        packageId: params.packageId!,
        room: params.room!,
      };

      const tokens = localStorage.getItem('detailsTokens');
      if (tokens) {
        const tokensJson: TokenPackage[] = JSON.parse(tokens);

        const token = tokensJson.find((token) => token.name === detailsParams.hotelName);
        if (token) {
          detailsParams.token = token.token;
        }
      }

      setDetailsParams(detailsParams);
      const newDetails = await getDetails(detailsParams);
      localStorage.setItem('details', JSON.stringify(newDetails));
      setDetails(newDetails);
    })();
  }, [params, executeRecaptcha]);

  const onSuccessPasswordForm = async (token: string) => {
    const newDetails = await getDetails({ ...detailsParams!, token });
    setDetails(newDetails);
    localStorage.setItem('details', JSON.stringify(newDetails));

    const tokens = localStorage.getItem('detailsTokens');
    const newToken: TokenPackage = { token, name: detailsParams?.hotelName! };
    if (tokens) {
      let tokensJson: TokenPackage[] = JSON.parse(tokens);
      if (tokensJson.findIndex((token) => token.name === detailsParams?.hotelName) === -1) {
        tokensJson = [...tokensJson, newToken];
      } else {
        tokensJson = tokensJson.filter((token) => token.name !== detailsParams?.hotelName);
        tokensJson.push(newToken);
      }
      localStorage.setItem('detailsTokens', JSON.stringify(tokensJson));
    } else {
      localStorage.setItem('detailsTokens', JSON.stringify([newToken]));
    }
  };

  return (
    <Fragment>
      {details && !details.HasErrors && (!details.Closed || details.Authorized) && (
        <div className="details-wrapper">
          <div className="details-first">
            <div className="details-center">
              <CarouselCard details={details} />
            </div>
            <div className="details-sidebar">
              <SummaryCard details={details} />
            </div>
          </div>

          <div className="details-title">מידע על הטיסה </div>
          <FlightCard
            flight={details.Flight.Departure}
            returnFlight={details.Flight.Arrival}
            icon={faPlaneDeparture}
            title="יציאה"
          />
          <FlightCard
            flight={details.Flight.ReturnDeparture}
            returnFlight={details.Flight.ReturnArrival}
            icon={faPlaneArrival}
            title="חזרה"
          />

          <InformationCard itinerary={details.Itinerary} />

          <IncludeCard include={details.IncludeInfo} />

          <div className="details-title">בחר חדרים</div>
          <RoomsCard roomsInfo={details.Rooms} url="book" isClosed={details.Closed} />
        </div>
      )}

      {details && details.Closed && !details.Authorized && (
        <PasswordForm onSuccess={onSuccessPasswordForm} />
      )}

      {details && details.HasErrors && !(details.Closed && !details.Authorized) && (
        <span>טיול לא נמצא, נסה אחר</span>
      )}

      {!details && <DetailsSkeleton />}
    </Fragment>
  );
};

export default DetailsPage;
