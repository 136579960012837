import React, { FC } from 'react';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';
import Carousel from 'react-material-ui-carousel';

interface CarouselCardProps {
  details: PackageDetails;
}

const CarouselCard: FC<CarouselCardProps> = ({ details }) => {
  return (
    <div className="details-info details-shadow">
      <div className="details-header">
        <span className="name" dangerouslySetInnerHTML={{ __html: details.Hotel.Name }}></span>
      </div>
      <div className="image">
        <Carousel autoPlay={false}>
          {details.Hotel.AllPhotos.map((photo, index) => (
            <img key={photo.Link} src={photo.Link} alt={photo.Alt} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselCard;
