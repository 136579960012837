import { Fragment, useEffect, useState } from 'react';
import Filter from '../components/Result/Filter';
import { getCountries, getMonths, getResults } from '../services/searchService';
import ResultCard from '../components/Result/ResultCard';
import { useParams } from 'react-router-dom';
import { Package } from '../ts/interfaces/Package';
import { AutocompleteItem } from '../ts/interfaces/Generic';
import { FilterModel } from '../ts/classes/FilterModel';
import Sorter from '../components/Result/Sorter';
import { SorterBy, SorterModel, SorterOrder } from '../ts/interfaces/Sorter';
import MainCarousel from '../components/Main/MainCarousel';
import ResultsListSkeleton from '../components/Skeleton/Results/ResultsListSkeleton';
import TableViewHeader from '../components/Result/TableViewHeader';
import { useGetPage } from '../hooks/useGetPage';

const SearchResultPage = () => {
  const [packages, setPackages] = useState<Package[] | undefined>();
  const [country, setCountry] = useState('');
  const [month, setMonth] = useState('');
  const [filter, setFilter] = useState<FilterModel | undefined>();
  const [allCountries, setAllCountries] = useState<AutocompleteItem[]>([]);
  const [allMonths, setAllMonths] = useState<AutocompleteItem[]>([]);
  const params = useParams();
  const page = useGetPage();

  useEffect(() => {
    (async () => {
      const newAllCountries = await getCountries();
      setAllCountries(newAllCountries);
      setCountry(newAllCountries.find((location) => location.value === params.location)?.label!);

      const storedMonths = sessionStorage.getItem('months');
      const newAllMonths: AutocompleteItem[] = !storedMonths
        ? await getMonths(params.location!)
        : JSON.parse(storedMonths);
      setAllMonths(newAllMonths);
      setMonth(newAllMonths.find((month) => month.value === params.month)?.label!);

      const newPackages = await getResults({ location: params.location!, month: params.month! });
      setPackages(newPackages);

      const newFilter = new FilterModel(newPackages);
      if (params.location === 'all') {
        newFilter.countries = newAllCountries.map((country) => country.label).slice(1);
      }
      if (params.month === 'all') {
        newFilter.months = newAllMonths.map((month) => month.label).slice(1);
      }

      setFilter(newFilter);
    })();
  }, [params]);

  const onSubmit = () => {
    setPackages(undefined);
    setFilter(undefined);
  };

  const onFilterChange = (filter: FilterModel) => {
    setFilter(filter);

    const newPackages = packages?.map((resert) => {
      const filterByNights = filter.nights.some((item) => item.checked);
      const filterByPackage = filter.packages.some((item) => item.checked);
      const filterByHotel = !!filter.selectedHotel;

      const priceFiltered =
        filter.prices.value[0] <= resert.Price.OnePersonPrice &&
        resert.Price.OnePersonPrice <= filter.prices.value[1];
      const nightsFiltered = filterByNights
        ? filter.nights.find((item) => item.count === resert.NightsCount)?.checked
        : true;
      const packagesFiltered = filterByPackage
        ? filter.packages.find((item) => item.name === resert.BoardDescription)?.checked
        : true;
      const hotelFiltered = filterByHotel ? filter.selectedHotel === resert.HotelName : true;

      let countryFiltered = true;
      if (params.location === 'all') {
        const countryCodes = allCountries
          .filter((country) => filter.selectedCountries.includes(country.label))
          .map((country) => country.value);
        countryFiltered = countryCodes.length ? countryCodes.includes(resert.CountryCode) : true;
      }

      let monthFiltered = true;
      if (params.month === 'all') {
        const dateCodes = allMonths
          .filter((month) => filter.selectedMonths.includes(month.label))
          .map((month) => parseFloat(month.value.split('-')[1]));
        monthFiltered = dateCodes.length
          ? dateCodes.some((month) => resert.CheckInMonthNumber === month)
          : true;
      }

      const visible =
        priceFiltered &&
        nightsFiltered &&
        packagesFiltered &&
        hotelFiltered &&
        countryFiltered &&
        monthFiltered;

      return { ...resert, visible: !!visible };
    });

    setPackages(newPackages);
  };

  const onFilterReset = () => {
    const newPackages = packages?.map((resert) => ({ ...resert, visible: true }));

    setFilter(
      new FilterModel(
        newPackages!,
        allCountries.map((c) => c.label).slice(1),
        allMonths.map((m) => m.label).slice(1),
      ),
    );

    setPackages(newPackages);
  };

  const onSorterChange = (sorter: SorterModel) => {
    const newPackages = packages?.sort((a, b) => {
      if (sorter.by === SorterBy.Name) {
        if (sorter.order === SorterOrder.Asc) {
          return a.HotelName.localeCompare(b.HotelName);
        } else {
          return b.HotelName.localeCompare(a.HotelName);
        }
      } else {
        if (sorter.order === SorterOrder.Asc) {
          return a.Price.OnePersonPrice - b.Price.OnePersonPrice;
        } else {
          return b.Price.OnePersonPrice - a.Price.OnePersonPrice;
        }
      }
    });

    setPackages([...newPackages!]);
  };

  return (
    <Fragment>
      <MainCarousel
        onSubmit={onSubmit}
        className="main-carousel-short"
        listOfGalleryId={page?.Carousel?.ListOfGalleryId || []}
      />

      {packages ? (
        <Fragment>
          <div className="results-header">
            <div className="results-title">
              <h2>טיולים ב {country}</h2>
              <div className="flex-vertical-center">
                <span>{`${packages.length} אפשרויות  · ${month}`}</span>
              </div>
            </div>
            <Sorter onChange={onSorterChange} />
          </div>

          <div className="results-main">
            <div className="results-filter">
              {!!filter && (
                <Filter filter={filter} onChange={onFilterChange} onReset={onFilterReset} />
              )}
            </div>
            <div className="results-list">
              <TableViewHeader />
              {packages
                .filter((resert) => resert.visible)
                .map((resert) => (
                  <ResultCard resert={resert} key={resert.index} />
                ))}
            </div>
          </div>
        </Fragment>
      ) : (
        <ResultsListSkeleton />
      )}
    </Fragment>
  );
};

export default SearchResultPage;
