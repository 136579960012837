import React, { FC, Fragment, useEffect, useState } from 'react';
import FormGroup from '../Ui/FormGroup';
import { firstPassengerValidation, shortPassengerValidation } from '../../constants/validation';
import { BookPayment } from '../../ts/interfaces/Book';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { formatPaxType } from '../../services/utilService';
import { RoomModel } from '../../ts/classes/RoomModel';
import { PaxModel } from '../../ts/classes/PaxModel';
import Suppliments from './Suppliments';

interface PassengersListProps {
  bookPayment?: BookPayment;
  rooms?: RoomModel[];
  isSubmitted: boolean;
  onChange: (rooms: RoomModel[]) => void;
  disabled?: boolean;
  currencySymbol: string;
  recountTotalPrice: (price: number) => void;
}

const PassengersList: FC<PassengersListProps> = (props) => {
  const [rooms, setRooms] = useState<RoomModel[]>([]);

  useEffect(() => {
    if (props.bookPayment) {
      const newRooms = props.bookPayment.RoomsInfo.map((room) => new RoomModel(room));
      setRooms(newRooms);
    } else if (props.rooms) {
      setRooms(props.rooms);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeText = (pax: PaxModel, roomIndex: number, paxIndex: number) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].paxes[paxIndex] = pax;
    setRooms(newRooms);
    props.onChange(newRooms);
  };

  const onChangeSuppliments = (rooms: RoomModel[], totalPrice: number) => {
    setRooms(rooms);
    props.onChange(rooms);
    props.recountTotalPrice(totalPrice);
  };

  return (
    <Fragment>
      {rooms.map((room, roomIndex) => (
        <Fragment key={roomIndex}>
          <div className="book-passenger-header">
            {room.paxes.map((_pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <FontAwesomeIcon icon={faUser} />{' '}
              </Fragment>
            ))}
            {room.description}
            <div className="text-bold">{room.classDescription}</div>
          </div>
          <div className="form-wrapper">
            {room.paxes.map((pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <div className="col-12 mb-10">
                  <FontAwesomeIcon icon={faUser} /> {formatPaxType(pax.typeDescription)}{' '}
                  {paxIndex + 1}
                </div>
                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(firstName) => onChangeText({ ...pax, firstName }, roomIndex, paxIndex)}
                  label="שם פרטי"
                  value={pax.firstName}
                  validation={shortPassengerValidation.firstName}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />
                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(lastName) => onChangeText({ ...pax, lastName }, roomIndex, paxIndex)}
                  label="שם משפחה"
                  value={pax.lastName}
                  validation={shortPassengerValidation.lastName}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />
                {paxIndex === 0 && roomIndex === 0 && (
                  <Fragment>
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(email) => onChangeText({ ...pax, email }, roomIndex, paxIndex)}
                      label='דוא"ל'
                      value={pax.email}
                      validation={firstPassengerValidation.email}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(phone) => onChangeText({ ...pax, phone }, roomIndex, paxIndex)}
                      label="טלפון"
                      value={pax.phone}
                      validation={firstPassengerValidation.phone}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                  </Fragment>
                )}

                <Suppliments
                  pax={pax}
                  paxIndex={paxIndex}
                  roomIndex={roomIndex}
                  rooms={rooms}
                  currencySymbol={props.currencySymbol}
                  onChange={onChangeSuppliments}
                />
              </Fragment>
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PassengersList;
