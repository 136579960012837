import { useParams } from 'react-router-dom';
import { PackageDetails } from '../ts/interfaces/PackageDetails';
import { useEffect, useState } from 'react';
import { PaymentCheck } from '../ts/interfaces/Book';
import { PaymentSuccess } from '../ts/interfaces/Success';
import FlightCard from '../components/Details/FlightCard';
import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import PassengersListClosedSuccess from '../components/Success/PassengerListClosedSuccess';
import PassengersListSuccess from '../components/Success/PassengerListSuccess';
import PassengersListSkiSuccess from '../components/Success/PassengerListSkiSuccess';
import HotelCardSuccess from '../components/Success/HotelCardSuccess';
import PayerSuccess from '../components/Success/PayerSuccess';

const SuccessOrderPage = () => {
  const params = useParams();
  const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);
  const detailsSuccess: PaymentSuccess = JSON.parse(localStorage.getItem('detailsSuccess')!);
  const [hotelStatus, setHotelStatus] = useState<string | undefined>();
  const [flightStatus, setFlightStatus] = useState<string | undefined>();

  useEffect(() => {
    const hotelStatus = detailsSuccess?.SystemOrder?.Segments[0].Details.find(
      (detail) => detail.ProductTypeCode === 'HTL',
    )?.SysStatus.Code;
    setHotelStatus(hotelStatus);

    const flightStatus = detailsSuccess?.SystemOrder?.Segments[0].Details.find(
      (detail) => detail.ProductTypeCode === 'FLT',
    )?.SysStatus.Code;
    setFlightStatus(flightStatus);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const win = window as any;
    const detailsOrder: PaymentCheck = JSON.parse(localStorage.getItem('successOrderDetails')!);

    if (win.dataLayer) {
      const data = detailsOrder.Data! || detailsOrder.DataSki || detailsOrder.DataTours;
      const deal = data.Deals[0];
      const actionField =
        params.type === 'request'
          ? {
              id: data.OrderId,
              revenue: 0,
            }
          : {
              id: data.TransactionId,
              revenue: data.TotalPrice,
            };

      const resultRequest = {
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField,
            products: [
              {
                id: deal.Id,
                name: deal.Package.Name,
                category: deal.Package.Category,
                price: deal.Package.Price[0],
                brand: '',
                variant: '',
                dimension1: 0,
                dimension2: details.Hotel.NightsCount,
                dimension3: deal.Package.Supplier,
                quantity: 1,
                coupon: '',
              },
            ],
          },
        },
      };
      win.dataLayer.push(resultRequest);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="details-wrapper book-wrapper">
      <div className="book-success-wrapper">
        <div className="book-success-title"> להזמין #{params.orderId}</div>

        {detailsSuccess?.MoneyWasTaken && (
          <div>
            <div className="mb-10 text-bold">ההזמנה בוצעה בהצלחה !</div>
            <div className="text-bold">
              כדי שההזמנה תאושר סופית נדרש לשלוח צילומי דרכון ל
              <a href="mailto:passport@ophirtours.co.il"> passport@ophirtours.co.il</a>
            </div>
          </div>
        )}
        {!detailsSuccess?.MoneyWasTaken && (
          <div>הזמנתך נקלטה במערכות נציג יחזור אלייך לאישור סופי</div>
        )}
      </div>
      <div className="details-center book-success-details">
        <div className="container">
          <FlightCard
            flight={details.Flight.Departure}
            returnFlight={details.Flight.Arrival}
            icon={faPlaneDeparture}
            title="יציאה"
            success={true}
            flightStatus={flightStatus}
          />
          <FlightCard
            flight={details.Flight.ReturnDeparture}
            returnFlight={details.Flight.ReturnArrival}
            icon={faPlaneArrival}
            title="חזרה"
            success={true}
            flightStatus={flightStatus}
          />

          <HotelCardSuccess hotelStatus={hotelStatus} />

          <div className="details-shadow book-passenger">
            <div className="details-header">
              <div className="name">פרטי הנוסעים</div>
            </div>
            {params.successView === 'tour' && <PassengersListSuccess />}
            {params.successView === 'ski' && <PassengersListSkiSuccess />}
            {params.successView === 'closed' && <PassengersListClosedSuccess />}
          </div>

          {detailsSuccess?.SystemOrder?.PaymentTransactionResponses?.[0].Reason && (
            <PayerSuccess
              reason={detailsSuccess?.SystemOrder.PaymentTransactionResponses[0].Reason}
              token={detailsSuccess?.SystemOrder.PaymentTransactionResponses[0].Token.split('‡')}
              status={detailsSuccess?.SystemOrder.PaymentTransactionResponses[0].Status}
            />
          )}
          <div className="success-total">
            <span className="texts">חופשה נעימה!</span>
            {detailsSuccess?.SystemOrder?.PaymentTransactionResponses?.[0] && (
              <span>
                <span>סה״כ: </span>
                <span>
                  {detailsSuccess?.SystemOrder?.PaymentTransactionResponses[0].Amount}{' '}
                  {detailsSuccess?.SystemOrder?.PaymentTransactionResponses[0].CurrencyCode}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessOrderPage;
