import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import FormGroup from '../Ui/FormGroup';
import {
  getFirstExpandedPassengerValidation,
  getShortExpandedPassengerValidation,
} from '../../constants/validation';
import { BookPayment } from '../../ts/interfaces/Book';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { formatPaxType } from '../../services/utilService';
import { RoomModel } from '../../ts/classes/RoomModel';
import { PaxModel } from '../../ts/classes/PaxModel';
import FormGroupDatepicker from '../Ui/FormGroudDatepicker';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';
import Suppliments from './Suppliments';
import { MenuItem, Select } from '@mui/material';
import { AutocompleteItem } from '../../ts/interfaces/Generic';
import { getNationalities } from '../../services/packageService';
import { sxDefaultDropdown, sxSearchSelect } from '../../constants/sx';

interface PassengersListExpandedProps {
  bookPayment?: BookPayment;
  rooms?: RoomModel[];
  isSubmitted: boolean;
  onChange: (rooms: RoomModel[]) => void;
  disabled?: boolean;
  currencySymbol: string;
  recountTotalPrice: (price: number) => void;
}

const PassengersListExpanded: FC<PassengersListExpandedProps> = (props) => {
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [visaRequired, setVisaRequired] = useState(false);
  const firstValidation = useRef(getFirstExpandedPassengerValidation(true));
  const shortValidation = useRef(getShortExpandedPassengerValidation(true));
  const [nationalities, setNationalities] = useState<AutocompleteItem[]>([]);

  useEffect(() => {
    if (props.bookPayment) {
      const newRooms = props.bookPayment.RoomsInfo.map((room) => new RoomModel(room));
      setRooms(newRooms);
    } else if (props.rooms) {
      setRooms(props.rooms);
    }

    const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);

    setVisaRequired(details.VisaRequired);

    firstValidation.current = getFirstExpandedPassengerValidation(details.VisaRequired);
    shortValidation.current = getShortExpandedPassengerValidation(details.VisaRequired);

    (async () => {
      const newNationalities = await getNationalities();
      setNationalities(newNationalities);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeText = (pax: PaxModel, roomIndex: number, paxIndex: number) => {
    const newRooms = [...rooms];
    newRooms[roomIndex].paxes[paxIndex] = pax;
    setRooms(newRooms);
    props.onChange(newRooms);
  };

  const onChangeSuppliments = (newRooms: RoomModel[], totalPrice: number) => {
    setRooms(newRooms);
    props.onChange(newRooms);
    props.recountTotalPrice(totalPrice);
  };

  return (
    <Fragment>
      {rooms.map((room, roomIndex) => (
        <Fragment key={roomIndex}>
          <div className="book-passenger-header">
            {room.paxes.map((_pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <FontAwesomeIcon icon={faUser} />{' '}
              </Fragment>
            ))}
            {room.description}
            <div className="text-bold">{room.classDescription}</div>
          </div>
          <div className="form-wrapper">
            {room.paxes.map((pax, paxIndex) => (
              <div key={paxIndex} className="book-passenger-pax form-wrapper">
                <div className="col-12 mb-10">
                  <FontAwesomeIcon icon={faUser} /> {formatPaxType(pax.typeDescription)}{' '}
                  {paxIndex + 1}
                </div>
                <div className="col-12 mb-10">נא למלא שם פרטי ושם משפחה באנגלית</div>
                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(firstName) => onChangeText({ ...pax, firstName }, roomIndex, paxIndex)}
                  label="שם פרטי"
                  value={pax.firstName}
                  validation={shortValidation.current.firstName}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />
                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(lastName) => onChangeText({ ...pax, lastName }, roomIndex, paxIndex)}
                  label="שם משפחה"
                  value={pax.lastName}
                  validation={shortValidation.current.lastName}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />
                <FormGroupDatepicker
                  className="col-12 col-md-6"
                  onChange={(birthDate) => onChangeText({ ...pax, birthDate }, roomIndex, paxIndex)}
                  label="תאריך לידה"
                  value={pax.birthDate}
                  validation={shortValidation.current.birthDate}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />
                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(tz) => onChangeText({ ...pax, tz }, roomIndex, paxIndex)}
                  label='ת"ז (תשע ספרות):'
                  value={pax.tz}
                  validation={firstValidation.current.tz}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                />

                <FormGroup
                  className="col-12 col-md-6"
                  onChange={(passportNumber) =>
                    onChangeText({ ...pax, passportNumber }, roomIndex, paxIndex)
                  }
                  label="מספר דרכון"
                  value={pax.passportNumber}
                  validation={shortValidation.current.passportNumber}
                  isSubmitted={props.isSubmitted}
                  disabled={props.disabled}
                  allowOnlyNumbers={true}
                />
                <FormGroupDatepicker
                  className="col-12 col-md-6"
                  onChange={(passportExpirationDate) =>
                    onChangeText({ ...pax, passportExpirationDate }, roomIndex, paxIndex)
                  }
                  label="תוקף דרכון"
                  value={pax.passportExpirationDate}
                  disabled={props.disabled}
                  validation={shortValidation.current.passportExpirationDate}
                  isSubmitted={props.isSubmitted}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                />

                <div className="col-12">
                  <div className="form-group">
                    <label>
                      אזרחות <span className="danger-color">*</span>
                    </label>
                    <Select
                      onChange={(event) =>
                        onChangeText(
                          { ...pax, nationality: event.target.value },
                          roomIndex,
                          paxIndex,
                        )
                      }
                      required
                      IconComponent={() => <></>}
                      defaultValue={'IL'}
                      sx={{ sxSearchSelect }}
                      MenuProps={{ PaperProps: { sx: sxDefaultDropdown } }}
                    >
                      {nationalities.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                {paxIndex === 0 && roomIndex === 0 && (
                  <Fragment>
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(email) => onChangeText({ ...pax, email }, roomIndex, paxIndex)}
                      label='דוא"ל'
                      value={pax.email}
                      validation={firstValidation.current.email}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(phone) => onChangeText({ ...pax, phone }, roomIndex, paxIndex)}
                      label="טלפון"
                      value={pax.phone}
                      validation={firstValidation.current.phone}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                  </Fragment>
                )}
                {visaRequired && (
                  <div className="book-passenger-visa form-wrapper">
                    <div className="book-passenger-visa-header">VISA</div>
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(fathersName) =>
                        onChangeText({ ...pax, fathersName }, roomIndex, paxIndex)
                      }
                      label="שמו של האב"
                      value={pax.fathersName}
                      validation={shortValidation.current.fathersName}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                    <FormGroup
                      className="col-12 col-md-6"
                      onChange={(mothersName) =>
                        onChangeText({ ...pax, mothersName }, roomIndex, paxIndex)
                      }
                      label="שם האם"
                      value={pax.mothersName}
                      validation={shortValidation.current.mothersName}
                      isSubmitted={props.isSubmitted}
                      disabled={props.disabled}
                    />
                    <FormGroup
                      className="col-12"
                      onChange={(birthCountry) =>
                        onChangeText({ ...pax, birthCountry }, roomIndex, paxIndex)
                      }
                      label="ארץ לידה"
                      value={pax.birthCountry}
                      disabled={props.disabled}
                    />
                  </div>
                )}

                <Suppliments
                  pax={pax}
                  paxIndex={paxIndex}
                  roomIndex={roomIndex}
                  rooms={rooms}
                  currencySymbol={props.currencySymbol}
                  onChange={onChangeSuppliments}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PassengersListExpanded;
