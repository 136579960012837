import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, Fragment, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { Credit2000PaymentInfo } from '../../ts/interfaces/Generic';
import { setButtonClasses } from '../../services/utilService';
import { sxDefaultDropdown, sxSearchSelect } from '../../constants/sx';

interface BookPaymentClosedProps {
  onClickSubmit: (info: Credit2000PaymentInfo) => void;
  disabled: boolean;
  credit2000?: Credit2000PaymentInfo;
}

const BookPaymentClosed: FC<BookPaymentClosedProps> = (props) => {
  const [normalList, setNormalList] = useState<any[]>([]);
  const [credit2000model, setCredit2000Model] = useState<Credit2000PaymentInfo | undefined>(
    props?.credit2000,
  );

  useEffect(() => {
    const newNormalList =
      props.credit2000?.ListOfRegularPayments.filter((payment) => !payment.Disabled).map(
        (payment) => ({
          value: +payment.Value,
          label: payment.Text,
        }),
      ) || [];

    setNormalList(newNormalList);
  }, [props.credit2000]);

  const onClickBook = () => {
    props.onClickSubmit(credit2000model!);
  };

  return (
    <Fragment>
      <div className="book-payment mb-20">
        <div className="title text-md mt-10" style={{ marginBottom: 10 }}>
          <FontAwesomeIcon icon={faCreditCard} className="mr-5 text-sm text-contrast" />
          <span className="mr-5">תשלום מאובטח ומהיר בכרטיס אשראי</span>
        </div>
        <div className="title text-md mb-20" style={{ color: 'var(--contrast-coloured-text)' }}>
          ניתן לשלם עד 3 תשלומים ללא ריבית
        </div>
        {!!props.credit2000 && (
          <div className="form-wrapper row">
            <div className="col-md-6 col-12">
              <div className="form-group">
                <div className="mb-5">'מספר תשלומים</div>
                <Select
                  sx={{ sxSearchSelect }}
                  MenuProps={{ PaperProps: { sx: sxDefaultDropdown } }}
                  onChange={(e) =>
                    setCredit2000Model({
                      ...credit2000model!,
                      NumberOfRegularPayments: +e.target.value,
                    })
                  }
                  value={credit2000model?.NumberOfRegularPayments}
                >
                  {normalList.map((payment) => (
                    <MenuItem key={payment.value} value={payment.value}>
                      {payment.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-md-3 col-12 book-payment-topbtn">
              <button
                style={{ marginTop: 22 }}
                className={setButtonClasses('button-st button-colored', props.disabled)}
                onClick={() => onClickBook()}
                disabled={props.disabled}
              >
                הזמן
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BookPaymentClosed;
