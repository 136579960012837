import { FC, useState } from 'react';
import FormGroup from '../components/Ui/FormGroup';
import Checkbox from '../components/Ui/Checkbox';
import { contactUsValidation } from '../constants/validation';
import { ContactUsModel } from '../ts/classes/ContactUsModel';
import FormGroupTextarea from '../components/Ui/FormGroupTextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useGetPage } from '../hooks/useGetPage';
import { validate } from '../services/validation';
import { contactUs } from '../services/contentService';
import { withSnackbar } from '../utils/Snackbar';

interface ContactPageProps {
  snackbarShowMessage: (message: string, variant: 'success' | 'error') => void;
}

const ContactPage: FC<ContactPageProps> = ({ snackbarShowMessage }) => {
  const page = useGetPage();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedRules, setIsSubmittedRules] = useState(false);
  const [request, setRequest] = useState(new ContactUsModel());

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (validate(contactUsValidation, request) && isSubmittedRules) {
      await contactUs(request);

      snackbarShowMessage('הפנייה נשלחה בהצלחה', 'success');

      setIsSubmitted(false);
      setRequest(new ContactUsModel());
    }
  };

  return (
    <form className="contact-wrapper" noValidate onSubmit={onSubmit}>
      <div className="main-title">צור קשר</div>
      <FormGroup
        onChange={(name) => setRequest({ ...request, name })}
        label="שם"
        value={request.name}
        validation={contactUsValidation.name}
        isSubmitted={isSubmitted}
      />
      <FormGroup
        onChange={(phone) => setRequest({ ...request, phone })}
        label="נייד"
        value={request.phone}
        validation={contactUsValidation.phone}
        isSubmitted={isSubmitted}
      />
      <FormGroup
        onChange={(email) => setRequest({ ...request, email })}
        label='נייד דוא"ל'
        value={request.email}
        validation={contactUsValidation.email}
        isSubmitted={isSubmitted}
      />
      <FormGroupTextarea
        onChange={(message) => setRequest({ ...request, message })}
        label="בקשה/הערה"
        value={request.message}
        validation={contactUsValidation.message}
        isSubmitted={isSubmitted}
      />
      <div className={isSubmitted && !isSubmittedRules ? 'danger-color' : ''}>
        <Checkbox
          label=" אני מאשר/ת קבלת מבצעים והטבות במייל"
          onSelect={(value) => setIsSubmittedRules(value)}
          value={false}
        />
      </div>

      <button className="mt-20 mb-10 button-st button-colored">שלח דוא"ל</button>
      <div>
        דוא"ל:
        <a href={`mailto: ${page?.cmsSite.Email1}`}>
          <FontAwesomeIcon icon={faEnvelope} />
          {page?.cmsSite.Email1}
          <p></p>
        </a>
      </div>
    </form>
  );
};

export default withSnackbar(ContactPage);
