import { FC, Fragment, useState } from 'react';
import SearchHead from './SearchHead';
import SearchTour from './SearchTour';
import SearchSki from './SearchSki';
import { useLocation } from 'react-router-dom';

interface SearchMainProps {
  onSubmit: () => void;
}

const SearchMain: FC<SearchMainProps> = (props) => {
  const location = useLocation();

  const [active, setActive] = useState<'tour' | 'ski'>('tour');

  return (
    <Fragment>
      <div className={'search-wrapper ' + (location.pathname === '/' ? 'search-wrapper-main' : '')}>
        <SearchHead onChangeActive={(value) => setActive(value)} active={active} />

        {(location.pathname.includes('tour') ||
          (location.pathname === '/' && active === 'tour')) && (
          <SearchTour onSubmit={props.onSubmit} />
        )}

        {(location.pathname.includes('ski') || (location.pathname === '/' && active === 'ski')) && (
          <SearchSki onSubmit={props.onSubmit} />
        )}
      </div>
    </Fragment>
  );
};

export default SearchMain;
