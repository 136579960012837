import React, { useEffect } from 'react';
import { ChangeEvent, FC, useState } from 'react';

interface CheckboxProps {
  onSelect: (item: boolean) => void;
  label: string;
  value: boolean;
  children?: React.ReactNode;
  textClassName?: string;
  disabled?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!props.disabled) {
      setValue(event.target.checked);
      props.onSelect(event.target.checked);
    }
  };

  return (
    <span className="checkbox-wr">
      <label>
        <input type="checkbox" onChange={onChange} checked={value} />
        <span className="checkmark"></span>
        <span className={props.textClassName}>
          {props.children}
          {props.label}
        </span>
      </label>
    </span>
  );
};

export default Checkbox;
