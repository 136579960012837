import { FC, useState } from 'react';
import FormGroup from '../components/Ui/FormGroup';
import { signInValidation } from '../constants/validation';
import { validate } from '../services/validation';
import { withSnackbar } from '../utils/Snackbar';
import { SignInModel } from '../ts/classes/SignInModel';
import { getUserInfo, signIn } from '../services/contentService';
import { useNavigate } from 'react-router-dom';
import { setButtonClasses } from '../services/utilService';

interface SignInPageProps {
  snackbarShowMessage: (message: string, variant: 'success' | 'error') => void;
}

const SignInPage: FC<SignInPageProps> = ({ snackbarShowMessage }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [credentials, setCredentials] = useState(new SignInModel());
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (validate(signInValidation, credentials)) {
      setIsDisabled(true);
      const result = await signIn(credentials);

      if (result.JwtToken) {
        sessionStorage.setItem('token', result.JwtToken);
        snackbarShowMessage('התחברת בהצלחה', 'success');
        setCredentials(new SignInModel());

        const userData = await getUserInfo();
        sessionStorage.setItem('userData', JSON.stringify(userData));

        navigate('/');
      } else {
        snackbarShowMessage('המשתמש הזין נתונים לא חוקיים', 'error');
      }

      setIsDisabled(false);
    }
  };

  return (
    <form className="contact-wrapper" noValidate onSubmit={onSubmit}>
      <div className="main-title">התחברות לחשבון קיים</div>
      <FormGroup
        onChange={(username) => setCredentials({ ...credentials, username })}
        label="שם משתמש"
        value={credentials.username}
        validation={signInValidation.username}
        isSubmitted={isSubmitted}
      />
      <FormGroup
        onChange={(password) => setCredentials({ ...credentials, password })}
        label="סיסמה"
        value={credentials.password}
        validation={signInValidation.password}
        isSubmitted={isSubmitted}
        type="password"
      />

      <button className={setButtonClasses('mt-20 mb-10 button-st button-colored', isDisabled)}>
        התחברות
      </button>
    </form>
  );
};

export default withSnackbar(SignInPage);
