import { Route, Routes, useLocation } from 'react-router-dom';
import { Page } from '../ts/interfaces/Generic';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import SearchResultPage from '../pages/SearchResultPage';
import DetailsPage from '../pages/DetailsPage';
import BookPage from '../pages/BookPage';
import PaymentPage from '../pages/PaymentPage';
import SuccessOrderPage from '../pages/SuccessOrderPage';
import { useLayoutEffect } from 'react';
import LandingOne from '../pages/LandingOne';
import ContactPage from '../pages/ContactPage';
import AboutPage from '../pages/AboutPage';
import InfoPage from '../pages/InfoPage';
import SearchSkiResultPage from '../pages/SearchSkiResultsPage';
import DetailsSkiPage from '../pages/DetailsSkiPage';
import BookSkiPage from '../pages/BookSkiPage';
import SignInPage from '../pages/SignInPage';

const Router = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const pages: Page[] = [
    { path: '/', component: MainPage },
    { path: '/tour/results/:location/:month', component: SearchResultPage },
    {
      path: '/details/:hotelName/:city/:checkIn/:nights/:dealId/:packageId/:room',
      component: DetailsPage,
    },
    {
      path: '/book/:hotelName/:city/:checkIn/:nights/:dealId/:packageId/:room',
      component: BookPage,
    },
    { path: '/payment/:successView/:orderToken/:generalType', component: PaymentPage },
    { path: '/success/:successView/:type/:orderId', component: SuccessOrderPage },
    { path: '/landing/:name', component: LandingOne },
    { path: '/tour/:tourpath', component: MainPage },
    { path: '/ski/:skipath', component: MainPage },
    { path: '/contact', component: ContactPage },
    { path: '/about', component: AboutPage },
    { path: '/signIn', component: SignInPage },
    { path: '/pages/info/:page', component: InfoPage },
    { path: '/ski/results/:city/:date', component: SearchSkiResultPage },
    {
      path: '/details/ski/:hotelName/:city/:checkIn/:nights/:dealId/:packageId/:room',
      component: DetailsSkiPage,
    },
    {
      path: '/book/ski/:hotelName/:city/:checkIn/:nights/:dealId/:packageId/:room',
      component: BookSkiPage,
    },
  ];

  return (
    <Routes>
      {pages.map(({ component: Component, path }) => {
        return <Route key={path} element={<Component />} path={path} />;
      })}
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};

export default Router;
