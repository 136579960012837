import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  getFirstExpandedPassengerValidation,
  firstPassengerValidation,
  getShortExpandedPassengerValidation,
  shortPassengerValidation,
  getPassengerSkiValidation,
} from '../../constants/validation';
import { validate } from '../../services/validation';
import { request, pay, payClosed } from '../../services/packageService';
import { withSnackbar } from '../../utils/Snackbar';
import { BookPayment, PayBook, PaymentCheck } from '../../ts/interfaces/Book';
import Checkbox from '../Ui/Checkbox';
import { RoomModel } from '../../ts/classes/RoomModel';
import { Modal, Tooltip } from '@mui/material';
import PassengersList from './PassengersList';
import { cutPaymentUrl, formatPrice, setButtonClasses } from '../../services/utilService';
import { useNavigate } from 'react-router-dom';
import PassengersListExpanded from './PassengersListClosed';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';
import PassengersListSki from './PassengersListSki';
import { paySki, requestSki } from '../../services/skiService';
import { CheckboxItem, Credit2000PaymentInfo } from '../../ts/interfaces/Generic';
import BookPaymentClosed from './BookPayment';

interface PassengersInfoProps {
  bookPayment: BookPayment;
  snackbarShowMessage: (message: string, variant: 'success' | 'error') => void;
  orderToken: string;
  isClosed?: boolean;
  isSki?: boolean;
}

const PassengersInfo: FC<PassengersInfoProps> = (props) => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [rooms, setRooms] = useState<RoomModel[]>([]);
  const [isSubmittedRules, setIsSubmittedRules] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const [conditions, setConditions] = useState<CheckboxItem[]>();

  useEffect(() => {
    if (props.bookPayment) {
      const newRooms = props.bookPayment.RoomsInfo.map((room) => new RoomModel(room));
      setRooms(newRooms);

      if (props.bookPayment.ListOfCheckOutLangs?.length) {
        const newConditions: CheckboxItem[] | undefined =
          props.bookPayment.ListOfCheckOutLangs[0].ListOfDesc?.map((lang) => ({
            label: lang.Name,
            checked: false,
          }));

        setConditions(newConditions);
      }
    }
  }, [props.bookPayment]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (isValid()) {
      setDisableSubmit(true);

      let result: PayBook;
      let successView: string;
      let generalType: string;
      if (props.isSki) {
        successView = 'ski';
        generalType = 'ski';
        result = await paySki(props.orderToken, rooms);
      } else {
        successView = 'tour';
        generalType = 'tour';
        result = await pay(props.orderToken, rooms);
      }

      if (result.HasErrors) {
        props.snackbarShowMessage(result.ErrorMessage, 'error');
      } else {
        localStorage.setItem('paymentUrl', cutPaymentUrl(result.PaymentUrl!));
        localStorage.setItem('rooms', JSON.stringify(rooms));

        navigate(`/payment/${successView}/${props.orderToken}/${generalType}`);
      }

      setDisableSubmit(false);
    }
  };

  const requestBook = async () => {
    setIsSubmitted(true);
    if (isValid()) {
      setDisableSubmit(true);
      let result: PaymentCheck;
      let successView: string;
      if (props.isSki) {
        successView = 'ski';
        result = await requestSki(props.orderToken, rooms);
      } else {
        successView = 'tour';
        result = await request(props.orderToken, rooms);
      }
      if (result.HasErrors) {
        props.snackbarShowMessage(result.ErrorMessage, 'error');
      } else {
        localStorage.setItem('rooms', JSON.stringify(rooms));
        localStorage.setItem('detailsSuccess', JSON.stringify(result));

        const data = result.DataTours ? result.DataTours : result.DataSki;
        navigate(`/success/${successView}/request/${data!.OrderId}`);
      }

      setDisableSubmit(false);
    }
  };

  const onClickOverlay = (event: any) => {
    if (event.target.className === 'modal-overlay') {
      setShowTerms(false);
    }
  };

  const isValid = () => {
    let result = true;
    rooms.forEach((room, roomIndex) => {
      room.paxes.forEach((pax, paxIndex) => {
        const validation = getValidation(roomIndex === 0 && paxIndex === 0, pax.paxCode);

        if (!validate(validation, pax)) {
          result = false;
        }

        const ksmlIndex = pax.suppliments.findIndex((sup) => JSON.stringify(sup).includes('KSML'));
        if (ksmlIndex !== -1) {
          if (!pax.suppliments[ksmlIndex].radioValue) {
            result = false;
          }
        }
      });
    });

    const validClosed = conditions?.every((con) => con.checked) && props.isClosed;
    const validNClosed = isSubmittedRules && !props.isClosed;
    return result && (validClosed || validNClosed);
  };

  const getValidation = (firstPax: boolean, paxCode: number) => {
    if (props.isClosed) {
      const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);

      return firstPax
        ? getFirstExpandedPassengerValidation(details.VisaRequired)
        : getShortExpandedPassengerValidation(details.VisaRequired);
    } else if (props.isSki) {
      return getPassengerSkiValidation(firstPax, paxCode);
    } else {
      return firstPax ? firstPassengerValidation : shortPassengerValidation;
    }
  };

  const onSubmitClosed = async (credit2000model: Credit2000PaymentInfo) => {
    setIsSubmitted(true);
    if (isValid()) {
      setDisableSubmit(true);

      let successView = 'closed';
      let generalType = 'tour';
      const result = await payClosed(props.orderToken, rooms, credit2000model);

      if (result.HasErrors) {
        props.snackbarShowMessage(result.ErrorMessage, 'error');
      } else {
        localStorage.setItem('paymentUrl', cutPaymentUrl(result.PaymentUrl!));
        localStorage.setItem('rooms', JSON.stringify(rooms));

        navigate(`/payment/${successView}/${props.orderToken}/${generalType}`);
      }

      setDisableSubmit(false);
    }
  };

  return (
    <Fragment>
      <form noValidate className="details-shadow book-passenger" onSubmit={onSubmit}>
        <div className="details-header">
          <div className="name">פרטי הנוסעים</div>
        </div>
        <div className="container">
          {!props.isClosed && !props.isSki && (
            <PassengersList
              bookPayment={props.bookPayment}
              isSubmitted={isSubmitted}
              onChange={(rooms) => setRooms(rooms)}
              currencySymbol={props.bookPayment.TotalPriceSymbol}
              recountTotalPrice={(price) => setAdditionalPrice(price)}
            />
          )}

          {props.isClosed && (
            <PassengersListExpanded
              bookPayment={props.bookPayment}
              isSubmitted={isSubmitted}
              onChange={(rooms) => setRooms(rooms)}
              currencySymbol={props.bookPayment.TotalPriceSymbol}
              recountTotalPrice={(price) => setAdditionalPrice(price)}
            />
          )}

          {props.isSki && (
            <PassengersListSki
              bookPayment={props.bookPayment}
              isSubmitted={isSubmitted}
              onChange={(rooms) => setRooms(rooms)}
              currencySymbol={props.bookPayment.TotalPriceSymbol}
              recountTotalPrice={(price) => setAdditionalPrice(price)}
            />
          )}

          {props.isClosed ? (
            <Fragment>
              {conditions?.map((condition, index) => (
                <div
                  key={index}
                  className={'col-12 ' + (isSubmitted && !condition.checked ? 'danger-color' : '')}
                >
                  <Checkbox
                    label={condition.label}
                    textClassName="text-bold"
                    onSelect={(value) => {
                      const newConditions = [...conditions];
                      newConditions[index].checked = value;
                      setConditions(newConditions);
                    }}
                    value={condition.checked}
                  />
                </div>
              ))}
            </Fragment>
          ) : (
            <div
              className={
                'col-12 inline-checkbox ' + (isSubmitted && !isSubmittedRules ? 'danger-color' : '')
              }
            >
              <Checkbox
                label="אנא אשר כי אתה מסכים לתנאים "
                onSelect={setIsSubmittedRules}
                value={isSubmittedRules}
              />
              <span
                className="text-default-color inline-link mr-4"
                onClick={() => setShowTerms(true)}
              >
                קרא עוד
              </span>
            </div>
          )}
          <div className="col-12 book-passenger-price">
            <span className="text-bold">
              מחיר כולל לעסקה{' '}
              {formatPrice(
                props.bookPayment.TotalPrice + additionalPrice,
                props.bookPayment.TotalPriceSymbol,
              )}
            </span>
            {props.bookPayment.TotalPriceSymbol !== '₪' && (
              <span>({formatPrice(props.bookPayment.PriceInShekels, '₪')})</span>
            )}
          </div>

          {props.isClosed && (
            <BookPaymentClosed
              credit2000={props.bookPayment.Credit2000PaymentSettings}
              onClickSubmit={onSubmitClosed}
              disabled={disableSubmit}
            />
          )}
          <div className="col-12 mb-20 book-passenger-submit">
            {!props.isClosed && (
              <Fragment>
                <Tooltip title="תשלום מאובטח ומהיר בכרטיס אשראי" placement="top">
                  <button
                    className={setButtonClasses('button-st button-colored', disableSubmit)}
                    disabled={disableSubmit}
                  >
                    הזמן
                  </button>
                </Tooltip>
                <Tooltip title="שלח בקשה לטיפול יועץ הנסיעות" placement="top">
                  <button
                    className={setButtonClasses('button-st mr-20', disableSubmit)}
                    disabled={disableSubmit}
                    type="button"
                    onClick={requestBook}
                  >
                    שלח בקשה
                  </button>
                </Tooltip>
              </Fragment>
            )}
          </div>
        </div>
      </form>
      <Modal
        open={showTerms}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-overlay" onClick={onClickOverlay}>
          <div className="details-shadow modal-dialog-st">
            <div className="details-header">
              <div className="name">{props.bookPayment.TermsTitle}</div>
            </div>
            <div
              className="modal-text-st"
              dangerouslySetInnerHTML={{ __html: props.bookPayment.TermsBodyHtml || '' }}
            ></div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default withSnackbar(PassengersInfo);
