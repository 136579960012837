import { RoomBook } from '../interfaces/Book';
import { PaxModel } from './PaxModel';

export class RoomModel {
  paxes: PaxModel[];
  description: string;
  classDescription: string;

  constructor(room: RoomBook) {
    this.paxes = room.Paxes.map((pax) => new PaxModel(pax));
    this.description = room.RoomDesc;
    this.classDescription = room.RoomClassDesc;
  }
}
