import { FC, useEffect, useState } from 'react';
import { getAbout } from '../services/contentService';
import TextSkeleton from '../components/Skeleton/TextSkeleton';

const AboutPage: FC = () => {
  const [about, setAbout] = useState('');

  useEffect(() => {
    (async () => {
      setAbout(await getAbout());
    })();
  }, []);

  if (about) {
    return (
      <div className="about">
        <div className="main-title">אודות</div>
        <div dangerouslySetInnerHTML={{ __html: about }}></div>
      </div>
    );
  } else {
    return <TextSkeleton />;
  }
};

export default AboutPage;
