import React, { Fragment } from 'react';
import ResultsListCardsSkeleton from './ResultsListCardsSkeleton';
import ResultsListHeaderSkeleton from './ResultsListHeaderSkeleton';
import ResultsListFilterSkeleton from './ResultsListFilterSkeleton';
import TableViewHeader from '../../Result/TableViewHeader';

const ResultsSkeleton = () => {
  return (
    <Fragment>
      <ResultsListHeaderSkeleton />
      <div className="results-main">
        <div className="results-filter">
          <ResultsListFilterSkeleton />
        </div>
        <div className="results-list">
          <TableViewHeader />
          <ResultsListCardsSkeleton />
        </div>
      </div>
    </Fragment>
  );
};

export default ResultsSkeleton;
