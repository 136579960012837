import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './styles/variables.scss';
import './styles/app.scss';
import './styles/reset.scss';
import './styles/header.scss';
import './styles/search.scss';
import './styles/main.scss';
import './styles/results.scss';
import './styles/footer.scss';
import './styles/filter.scss';
import './styles/components.scss';
import './styles/details.scss';
import './styles/itinerary.scss';
import './styles/flight.scss';
import './styles/book.scss';
import './styles/sales.scss';
import './styles/landing.scss';
import './styles/loader.scss';
import './styles/accessibility.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
