import { Skeleton } from '@mui/material';
import React from 'react';

const ResultsFilterSkeleton = () => {
  return (
    <div className="filter">
      <FilterInputSkeleton />
      <FilterInputSkeleton />

      <FilterCheckboxSkeleton />
      <FilterInputSkeleton />
      <FilterCheckboxSkeleton />
    </div>
  );
};

const FilterInputSkeleton = () => {
  return (
    <div className="filter-item">
      <span className="filter-item-title">
        <Skeleton variant="rectangular" animation="wave" height={16} width={100} />
      </span>

      <div className="filter-item-content">
        <div className="full">
          <Skeleton variant="rectangular" animation="wave" height={39} width={'100%'} />
        </div>
      </div>
    </div>
  );
};

const FilterCheckboxSkeleton = () => {
  return (
    <div className="filter-item">
      <span className="filter-item-title">
        <Skeleton variant="rectangular" animation="wave" height={16} width={120} />
      </span>
      <div className="filter-item-content">
        {[...Array(3)].map((_, index) => (
          <div className="with-price" key={index}>
            <Skeleton variant="rectangular" animation="wave" height={16} width={100} />
            <span className="price">
              <Skeleton variant="rectangular" animation="wave" height={16} width={60} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsFilterSkeleton;
