import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Photo } from '../../ts/interfaces/Package';

interface GalleryProps {
  images: Photo[];
}

const Gallery: FC<GalleryProps> = ({ images }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);

  const openModal = () => {
    setShowGallery(true);
    setTimeout(() => {
      setModalHeight(document.querySelector('.modal-dialog-st')?.clientHeight! - 63);
    }, 0);
  };

  const onClickOverlay = (event: any) => {
    if (event.target.className === 'modal-overlay') {
      setShowGallery(false);
    }
  };

  return (
    <div>
      <div className="sales-card-gallery" onClick={openModal}>
        <FontAwesomeIcon icon={faImages} />
        גלריה
      </div>
      <Modal
        open={showGallery}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-overlay" onClick={onClickOverlay}>
          <div className="details-shadow modal-dialog-st">
            <div className="modal-header">
              <div className="name"> גלריה</div>

              <FontAwesomeIcon icon={faTimes} onClick={() => setShowGallery(false)} />
            </div>

            <div className="modal-slider" style={{ height: modalHeight + 'px' }}>
              {modalHeight && (
                <Carousel autoPlay={false}>
                  {images.map((photo) => (
                    <img
                      key={photo.Link}
                      src={photo.Link}
                      alt={photo.Alt}
                      style={{ height: modalHeight - 50 + 'px' }}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Gallery;
