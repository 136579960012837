import { AutocompleteItem } from '../interfaces/Generic';
import { Package } from '../interfaces/Package';

export interface FilterNights {
  count: number;
  checked: boolean;
  price: number;
  currency: string;
}

export interface FilterPackage {
  name: string;
  checked: boolean;
  price: number;
  currency: string;
}

export interface FilterRating {
  count: number;
  checked: boolean;
  price: number;
  currency: string;
}

export class FilterModel {
  hotels: string[];
  selectedHotel: string;
  prices: {
    min: number;
    max: number;
    value: [number, number];
  };
  nights: FilterNights[];
  packages: FilterPackage[];
  ratings?: FilterRating[];

  countries: string[];
  selectedCountries: string[];
  months: string[];
  selectedMonths: string[];

  constructor(
    packages: Package[],
    countries?: string[],
    months?: string[],
    isSki?: boolean,
    allCountries?: AutocompleteItem[],
  ) {
    this.hotels = packages
      .map((item) => item.HotelName)
      .filter((item, index, array) => array.indexOf(item) === index)
      .sort();
    this.selectedHotel = '';

    this.nights = packages
      .map((item) => ({
        count: item.NightsCount,
        checked: false,
        price: 0,
        currency: '',
      }))
      .filter(
        (item, index, array) =>
          array.findIndex((element) => element.count === item.count) === index,
      )
      .sort((a, b) => a.count - b.count);
    this.nights.forEach((item) => {
      const prices = packages
        .filter((element) => element.NightsCount === item.count)
        .map((element) => element.Price.OnePersonPrice);
      item.price = Math.min(...prices);
      item.currency = packages.find(
        (el) => el.Price.OnePersonPrice === item.price,
      )!.Price.CurrencySymbol;
    });

    this.packages = packages
      .map((item) => ({
        name: item.BoardDescription,
        checked: false,
        price: item.Price.OnePersonPrice,
        currency: '',
      }))
      .filter(
        (item, index, array) => array.findIndex((element) => element.name === item.name) === index,
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    this.packages.forEach((item) => {
      const prices = packages
        .filter((element) => element.BoardDescription === item.name)
        .map((element) => element.Price.OnePersonPrice);
      item.price = Math.min(...prices);
      item.currency = packages.find(
        (el) => el.Price.OnePersonPrice === item.price,
      )!.Price.CurrencySymbol;
    });

    const prices = packages.map((item) => item.Price.OnePersonPrice);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    this.prices = {
      min: minPrice,
      max: maxPrice,
      value: [minPrice, maxPrice],
    };

    if (isSki) {
      this.ratings = packages
        .map((item) => ({
          count: item.Stars,
          checked: false,
          price: 0,
          currency: '',
        }))
        .filter(
          (item, index, array) =>
            array.findIndex((element) => element.count === item.count) === index,
        )
        .sort((a, b) => a.count - b.count);
      this.ratings.forEach((item) => {
        const prices = packages
          .filter((element) => element.Stars === item.count)
          .map((element) => element.Price.OnePersonPrice);
        item.price = Math.min(...prices);
        item.currency = packages.find(
          (el) => el.Price.OnePersonPrice === item.price,
        )!.Price.CurrencySymbol;
      });

      this.months = packages.map((item) => item.UrlFriendlyCheckIn);
      this.countries = packages
        .map((item) => item.City)
        .filter((item, index, array) => array.indexOf(item) === index);

      this.countries = this.countries.map((item) => {
        const city = allCountries!.find((el) => el.value === item);
        return city ? city.label : item;
      });
    } else {
      this.months = months || [];
      this.countries = countries || [];
    }

    this.selectedCountries = [];
    this.selectedMonths = [];
  }
}
