import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

const TextSkeleton: FC = () => {
  return (
    <div className="main-text">
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={28.5}
        width={180}
        sx={{ margin: '0 auto 5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'80%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'90%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'89%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'78%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'90%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'95%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'100%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'60%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'90%'}
        sx={{ mb: '5px', mt: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'95%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'78%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'90%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'95%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'100%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'60%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'100%'}
        sx={{ mb: '5px' }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={26}
        width={'60%'}
        sx={{ mb: '5px' }}
      />
    </div>
  );
};

export default TextSkeleton;
