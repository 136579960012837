import React, { FC, SyntheticEvent } from 'react';
import { FlightPart } from '../../ts/interfaces/Flight';

interface FlightCardSmaillProps {
  flight: FlightPart;
  returnFlight: FlightPart;
}

const FlightCardSmaill: FC<FlightCardSmaillProps> = ({ flight, returnFlight }) => {
  const onImageLoadError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = require('../../assets/images/airplane.png');
  };
  return (
    <div className="ticket-sm">
      <div className="ticket-sm-logo">
        <img src={flight.AirlineLogo} alt="logo" onError={onImageLoadError} />
        <span className="name">{flight.AirlineDesc}</span>
      </div>
      <div className="ticket-sm-cell short">
        <span>{flight.CityCode}</span>
      </div>
      <div className="ticket-sm-cell date">
        <div>{flight.DateShort}</div>
        <div>{flight.Time}</div>
      </div>
      <div className="ticket-sm-cell short">
        <span>{returnFlight.CityCode}</span>
      </div>
      <div className="ticket-sm-cell date">
        <div>{returnFlight.DateShort}</div>
        <div>{returnFlight.Time}</div>
      </div>
    </div>
  );
};

export default FlightCardSmaill;
