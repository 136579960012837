import { FC, Fragment, useEffect, useState } from 'react';
import DisplayGroup from '../Ui/DisplayGroup';
import { ReasonPayer } from '../../ts/interfaces/Success';

interface PayerSuccessProps {
  reason: string;
  token: string[];
  status: number;
}

const PayerSuccess: FC<PayerSuccessProps> = ({ reason, token, status }) => {
  const [reasonObj, setReasonObj] = useState<ReasonPayer>();

  useEffect(() => {
    const newReason = reason.split('*');

    const newReasonObj = newReason
      .filter((item) => item)
      .reduce((acc, item) => {
        const newItem = item.split('=');
        return { ...acc, [newItem[0]]: newItem[1].replace(`\n`, '') };
      }, {});

    setReasonObj(newReasonObj as ReasonPayer);
  }, [reason]);

  const statusToStr = (status: number) => {
    if (status === 0) return 'Approved';
    if (status === 1) return 'Success';
    if (status === 2) return 'Declined';
    if (status === 3) return 'Error';
    if (status === 4) return 'Pending';
  };

  const statusToClassName = (status: number) => {
    if (status === 0) return 'warning';
    if (status === 1) return 'ok';
    if (status === 2) return 'err';
    if (status === 3) return 'err';
    if (status === 4) return 'err';
  };

  return (
    <Fragment>
      {reasonObj && (
        <div className="details-shadow book-passenger">
          <div className="details-header">
            <div className="name">פרטי תשלום</div>
          </div>
          <div className="form-wrapper">
            <DisplayGroup
              value={reasonObj.FName + ' ' + reasonObj.LName}
              label="שם בעל הכרטיס"
              className="col-12 col-md-6"
            />
            <DisplayGroup
              value={reasonObj.Purchase_Type}
              label="סוג עסקה"
              className="col-12 col-md-6"
            />
            <DisplayGroup value={reasonObj.Token} label="מזהה כרטיס" className="col-12 col-md-6" />
            <DisplayGroup value={token[2]} label="מספר אסמכתא" className="col-12 col-md-6" />
            <DisplayGroup
              value={reasonObj.ValidDate}
              label="תוקף הכרטיס"
              className="col-12 col-md-6"
            />
            <DisplayGroup
              value={reasonObj.Tz}
              label="מספר תעודת זהות"
              className="col-12 col-md-6"
            />
            <DisplayGroup
              value={reasonObj.FirstPayment}
              label="תשלום"
              className="col-12 col-md-6"
            />
            <DisplayGroup
              value={parseInt(reasonObj.PaymentsCount) + ' תשלום'}
              label="צורת תשלום"
              className="col-12 col-md-6"
            />
            <DisplayGroup
              value={reasonObj.TotalPayment}
              label='סה"כ לתשלום'
              className="col-12 col-md-6"
            />

            <div className="col-12 success-ticket-ok">
              <span></span>
              <span className={statusToClassName(status)}>{statusToStr(status)}</span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PayerSuccess;
