import React, { FC } from 'react';
import { Room } from '../../ts/interfaces/PackageDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faList, faUser, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { roomTypeFilter } from '../../services/utilService';

interface RoomsSummaryProps {
  rooms: Room[];
}

const RoomsSummary: FC<RoomsSummaryProps> = ({ rooms }) => {
  return (
    <div className="details-shadow">
      <div className="rooms-table">
        <div className="rooms-table-header">
          <div className="rooms-table-cell">
            <FontAwesomeIcon icon={faHotel} /> סוג החדר
          </div>
          <div className="rooms-table-cell">
            <FontAwesomeIcon icon={faUtensils} /> בסיס אירוח
          </div>
          <div className="rooms-table-cell">
            <FontAwesomeIcon icon={faList} /> כמות
          </div>
        </div>
        {rooms.map((room, index) => (
          <div className="rooms-table-row" key={index}>
            <div className="rooms-table-cell">
              <div>
                {Array(room?.NumPax)
                  .fill('')
                  .map((_, index) => (
                    <span key={index}>
                      <FontAwesomeIcon icon={faUser} />{' '}
                    </span>
                  ))}{' '}
                <span className="text-bold">{roomTypeFilter(room?.RoomCode)}</span>
              </div>
              <div>{room.RoomDesc}</div>
            </div>
            <div className="rooms-table-cell">{room.BoardDesc}</div>
            <div className="rooms-table-cell">1x</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomsSummary;
