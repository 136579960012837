import React, { FC } from 'react';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';
import {
  faCalendar,
  faCar,
  faMoon,
  faPlane,
  faSnowflake,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SummaryCardProps {
  details: PackageDetails;
}

const SummaryCard: FC<SummaryCardProps> = ({ details }) => {
  return (
    <div className="details-shadow details-summary">
      <div className="details-sidebar-header">
        <span className="name" dangerouslySetInnerHTML={{ __html: details.Hotel.Name }}></span>
      </div>
      <div className="details-sidebar-list">
        <div className="details-sidebar-list-item">
          <FontAwesomeIcon icon={faCalendar} />
          כניסה: {details.Hotel.CheckIn}
        </div>
        <div className="details-sidebar-list-item">
          <FontAwesomeIcon icon={faCalendar} />
          יציאה: {details.Hotel.CheckOut}
        </div>
        <div className="details-sidebar-list-item">
          <FontAwesomeIcon icon={faPlane} />
          שדה תעופה:{' '}
          <span
            dangerouslySetInnerHTML={{ __html: details.Flight.ArrivalAirport.Name }}
          ></span> , {details.Flight.ArrivalAirport.Code}
        </div>
        <div className="details-sidebar-list-item">
          <FontAwesomeIcon icon={faMoon} />
          מספר לילות: {details.Hotel.NightsCount}
        </div>
        {details.Suppliments.GuideName && (
          <div className="details-sidebar-list-item">
            <FontAwesomeIcon icon={faUser} />
            {details.Suppliments.GuideName}
          </div>
        )}
        {details.Suppliments.HasTransfer && (
          <div className="details-sidebar-list-item">
            <FontAwesomeIcon icon={faCar} />
            <span>העברות: העברות כלולות במחיר</span>
          </div>
        )}
        {details.SupplimentsFull?.map((sup) => (
          <div className="details-sidebar-list-item">
            <FontAwesomeIcon icon={faSnowflake} />
            <span>{sup.NameLoc || sup.Name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryCard;
