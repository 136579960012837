import { FC, useState } from 'react';
import FormGroup from '../Ui/FormGroup';
import { setButtonClasses } from '../../services/utilService';
import { validate } from '../../services/validation';
import { detailsPasswordValidation } from '../../constants/validation';
import { useParams } from 'react-router-dom';
import { loginToPackage } from '../../services/packageService';
import { withSnackbar } from '../../utils/Snackbar';

interface PasswordFormProps {
  onSuccess: (token: string) => void;
  snackbarShowMessage: (message: string, variant: 'success' | 'error') => void;
}

const PasswordForm: FC<PasswordFormProps> = ({ onSuccess, snackbarShowMessage }) => {
  const params = useParams();
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const requestDetails = async () => {
    const valid = validate(detailsPasswordValidation, { password });
    setIsSubmitted(true);
    if (valid) {
      setDisableSubmit(true);
	  console.log(params);
      const authToken = await loginToPackage(params.dealId!, password, params.checkIn!);
      if (authToken) {
        onSuccess(authToken);
      } else {
        snackbarShowMessage('סיסמה שגויה', 'error');
        setDisableSubmit(false);
      }
    }
  };

  return (
    <div className="details-password">
      <div className="form-wrapper details-shadow">
        <div className="form-header">נא להזין את הסיסמה</div>
        <FormGroup
          onChange={setPassword}
          label="סיסמה"
          className="col-12"
          value={password}
          type="password"
          validation={detailsPasswordValidation.password}
          isSubmitted={isSubmitted}
        />

        <div className="col-12">
          <button
            className={setButtonClasses('button-st button-colored', disableSubmit)}
            disabled={disableSubmit}
            onClick={requestDetails}
          >
            התחברות
          </button>
        </div>
      </div>
    </div>
  );
};
export default withSnackbar(PasswordForm);
