import React, { useCallback, useState } from 'react';
import { FC } from 'react';
import { ValidationBody } from '../../ts/interfaces/Validation';
import { getErrorInField } from '../../services/validation';
import DatePicker from 'react-date-picker';

interface FormGroupDatepickerProps {
  onChange: (item: Date | null) => void;
  className: string;
  value: Date | null;
  label: string;
  validation?: ValidationBody;
  isSubmitted?: boolean;
  disabled?: boolean;
  maxDate?: Date;
}

const FormGroupDatepicker: FC<FormGroupDatepickerProps> = (props) => {
  const [value, setValue] = useState(props.value);

  const isValid = useCallback(() => {
    return getErrorInField(props.validation!, value?.toString() || '', props.isSubmitted!);
  }, [value, props.isSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.className}>
      <div className="form-group">
        <label>
          {props.label} {props.validation?.required && <span className="danger-color">*</span>}
        </label>
        <div
          className={
            'form-control react-date-picker-custom ' +
            (isValid() ? 'is-invalid ' : '') +
            (!value ? 'react-date-picker-empty' : '')
          }
        >
          <DatePicker
            value={value}
            locale="he-IL"
            format="d/M/yyyy"
            clearIcon={null}
            dayPlaceholder=""
            monthPlaceholder=""
            yearPlaceholder=""
            maxDate={props.maxDate}
            onChange={(date) => {
              setValue(date as Date);
              props.onChange(date as Date);
            }}
          />
        </div>
      </div>
    </div>
  );
};

FormGroupDatepicker.defaultProps = {
  validation: {},
  isSubmitted: false,
};

export default FormGroupDatepicker;
