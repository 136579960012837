import React, { FC, useEffect, useState } from 'react';
import { RoomInfo } from '../../ts/interfaces/PackageDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { sxDefaultDropdown, sxSearchSelect } from '../../constants/sx';
import { roomsCountSelect } from '../../constants/generic';
import { formatPrice } from '../../services/utilService';
import { useNavigate, useParams } from 'react-router-dom';

interface RoomsCardProps {
  roomsInfo: RoomInfo[];
  url: string;
  isClosed?: boolean;
}

interface RoomForCount {
  index: number;
  count: number;
  topIndex: number;
}

const RoomsCard: FC<RoomsCardProps> = ({ roomsInfo, url, isClosed }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [total, setTotal] = useState(0);

  const [roomsForCount, setRoomsForCount] = useState(
    roomsInfo.reduce((acum: RoomForCount[], roomInfo, topIndex) => {
      return acum.concat(
        roomInfo.Rooms.map((_, index) => {
          const count = isClosed && topIndex === 0 && index === 0 ? 1 : 0;
          return { index, count, topIndex };
        }),
      );
    }, []),
  );

  useEffect(() => {
    recountTotalPrice(roomsForCount);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPressContinue = () => {
    const path = `/${url}/${params.hotelName}/${params.city}/${params.checkIn}/${params.nights}/${params.dealId}/${params.packageId}/`;
    const choosenRooms = roomsForCount.filter((room) => room.count);

    const roomsFormatted = roomsInfo.reduce((acum, roomInfo, topIndex) => {
      const result = roomInfo.Rooms.reduce((total, room, index) => {
        const choosenRoom = choosenRooms.find(
          (choosenRoom) => choosenRoom.index === index && choosenRoom.topIndex === topIndex,
        );
        if (choosenRoom) {
          return `${total}${room.RoomCode}-${room.RoomType}-${choosenRoom.count},`;
        } else {
          return total;
        }
      }, '');
      return acum + result;
    }, '');
    navigate(path + roomsFormatted.slice(0, -1));
  };

  const setTotalPrice = (
    event: SelectChangeEvent<unknown>,
    roomIndex: number,
    roomTopIndex: number,
  ) => {
    const newIndex = roomsForCount.findIndex(
      (room) => room.index === roomIndex && room.topIndex === roomTopIndex,
    );

    const newRoomsForCount = [
      ...roomsForCount.slice(0, newIndex),
      { index: roomIndex, count: event.target.value as number, topIndex: roomTopIndex },
      ...roomsForCount.slice(newIndex + 1),
    ];
    setRoomsForCount(newRoomsForCount);

    recountTotalPrice(newRoomsForCount);
  };

  const recountTotalPrice = (newRoomsForCount: RoomForCount[]) => {
    const newTotal = newRoomsForCount.reduce((total, room) => {
      return total + roomsInfo[room.topIndex].Rooms[room.index].PriceTotal * room.count;
    }, 0);

    setTotal(newTotal);
  };

  const areRooms = () => {
    return roomsForCount.some((room) => room.count != 0); // eslint-disable-line eqeqeq
  };

  return (
    <div className="details-shadow">
      <div className="details-room-row details-room-header">
        <span className="details-room-col details-room-name"> סוג חדר </span>
        <span className="details-room-col">מספר חדרים </span>
        <span className="details-room-col">
          <span>מחיר לאדם</span>
        </span>
        <span className="details-room-col">
          <span>כללי</span>
        </span>
      </div>
      <div>
        {roomsInfo.map((roomInfo, roomTopIndex) => (
          <div key={roomTopIndex} className="details-room-block">
            {roomInfo.Rooms.map((room, roomIndex) => (
              <div className="details-room-row" key={roomIndex}>
                <span className="details-room-col details-room-name">
                  <span>
                    <FontAwesomeIcon icon={faBed} />
                    {room.RoomDesc}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faUtensils} /> {room.BoardDesc}
                  </span>
                </span>
                <span className="details-room-col">
                  <Select
                    onChange={(event) => setTotalPrice(event, roomIndex, roomTopIndex)}
                    IconComponent={() => <></>}
                    sx={{ sxSearchSelect }}
                    MenuProps={{ PaperProps: { sx: sxDefaultDropdown } }}
                    defaultValue={roomsForCount[roomTopIndex].count}
                    disabled={isClosed}
                  >
                    {roomsCountSelect.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </span>
                <span className="details-room-col details-room-price">
                  <span className="text-bold">
                    <span className="mobile-diplay">מחיר לאדם /</span>
                    <span className="details-room-price-val">
                      {formatPrice(room.PricePerPax, roomInfo.CurrencySymbol)}
                    </span>
                  </span>
                </span>
                <span className="details-room-col details-room-price">
                  <span>
                    <span className="mobile-diplay">סה"כ /</span>
                    <span className="details-room-price-val">
                      {formatPrice(room.PriceTotal, roomInfo.CurrencySymbol)}
                    </span>
                  </span>
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="details-room-footer">
        <div className="price">
          <span className="title">סה"כ: </span>
          {formatPrice(total, roomsInfo[0].CurrencySymbol)}
        </div>
        <button
          className={'button-st button-colored' + (!areRooms() ? ' button-disabled' : '')}
          onClick={onPressContinue}
          disabled={!areRooms()}
        >
          המשך
        </button>
      </div>
    </div>
  );
};

export default RoomsCard;
