export enum SorterBy {
  None,
  Name,
  Price,
}

export enum SorterOrder {
  None,
  Asc,
  Desc,
}

export interface SorterModel {
  by: SorterBy;
  order: SorterOrder;
}
