import React, { FC, useState } from 'react';
import { PassengerInfoModel } from '../../ts/classes/PassengerInfoModel';
import FormGroup from '../Ui/FormGroup';
import { passengerValidation } from '../../constants/validation';
import { validate } from '../../services/validation';
import { withSnackbar } from '../../utils/Snackbar';
import { BookPayment } from '../../ts/interfaces/Book';
import { setButtonClasses } from '../../services/utilService';

interface PassengerInfoProps {
  onSuccess: (book: BookPayment) => void;
  orderToken: string;
  snackbarShowMessage: (message: string, variant: 'success' | 'error') => void;
  bookMethod: (orderToken: string, passenger: PassengerInfoModel) => Promise<BookPayment>;
}

const PassengerInfo: FC<PassengerInfoProps> = (props) => {
  const [passenger, setPassenger] = useState(new PassengerInfoModel());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (validate(passengerValidation, passenger)) {
      setDisableSubmit(true);
      const result = (await props.bookMethod(props.orderToken, passenger))!;
      if (result.HasErrors) {
        props.snackbarShowMessage(result.ErrorMessage, 'error');
      } else {
        props.onSuccess(result);
      }

      setDisableSubmit(false);
    }
  };

  return (
    <form noValidate className="details-shadow book-passenger" onSubmit={onSubmit}>
      <div className="details-header">
        <div className="name">פרטי הנוסעים</div>
      </div>
      <div className="form-wrapper">
        <div className="col-12 mb-10">
          <span className="danger-color">נא למלא שם פרטי ושם משפחה באנגלית כפי שמופיע בדרכון</span>
        </div>
        <FormGroup
          value={passenger.firstName}
          label="שם פרטי"
          className="col-12 col-md-6"
          onChange={(firstName) => setPassenger({ ...passenger, firstName })}
          validation={passengerValidation.firstName}
          isSubmitted={isSubmitted}
        />
        <FormGroup
          value={passenger.lastName}
          label="שם משפחה"
          className="col-12 col-md-6"
          onChange={(lastName) => setPassenger({ ...passenger, lastName })}
          validation={passengerValidation.lastName}
          isSubmitted={isSubmitted}
        />
        <FormGroup
          value={passenger.email}
          label='דוא"ל'
          className="col-12 col-md-6"
          onChange={(email) => setPassenger({ ...passenger, email })}
          validation={passengerValidation.email}
          isSubmitted={isSubmitted}
        />
        <FormGroup
          value={passenger.phone}
          label="טלפון"
          className="col-12 col-md-6"
          onChange={(phone) => setPassenger({ ...passenger, phone })}
          validation={passengerValidation.phone}
          isSubmitted={isSubmitted}
          allowOnlyNumbers={true}
        />
        <FormGroup
          value={passenger.notes}
          label="בקשה/הערה"
          className="col-12"
          onChange={(notes) => setPassenger({ ...passenger, notes })}
          validation={passengerValidation.notes}
          isSubmitted={isSubmitted}
        />

        <div className="col-12 book-passenger-submit">
          <button
            className={setButtonClasses('button-st button-colored', disableSubmit)}
            disabled={disableSubmit}
          >
            המשך הזמנה
          </button>
        </div>
      </div>
    </form>
  );
};

export default withSnackbar(PassengerInfo);
