import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPaymentStatus } from '../services/packageService';
import PaymentSkeleton from '../components/Skeleton/PaymentSkeleton';

const PaymentPage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [paymentUrl, setPaymentUrl] = useState('');
  const [errorText, setErrorText] = useState('');
  const [status, setStatus] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const url = localStorage.getItem('paymentUrl');
    if (url) {
      setPaymentUrl(url);
    }

    return () => clearTimeout(timeoutRef.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (paymentUrl) {
      checkPayment();
    }
  }, [paymentUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPayment = async () => {
    const result = await checkPaymentStatus(params.orderToken!, params.generalType!);
    setStatus(result.Status);

    if (result.HasErrors) {
      setErrorText(result.StatusDescription);
      setTimeout(() => setErrorText(''), 10000);
    } else {
      if (result.Status === 'OK') {
        localStorage.removeItem('paymentUrl');
        localStorage.removeItem('orderToken');
        localStorage.setItem('detailsSuccess', JSON.stringify(result));
        const data = result.DataTours ? result.DataTours : result.DataSki;
        navigate(`/success/${params.successView}/payment/${data!.OrderId}`);
      } else if (result.Status === 'FAIL') {
        setErrorText(result.StatusDescription);
        setTimeout(() => setErrorText(''), 10000);
      } else {
        timeoutRef.current = setTimeout(() => {
          checkPayment();
        }, 4000);
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-iframe" style={{ height: 1000 }}>
        <PaymentSkeleton />
        <iframe src={paymentUrl} title="0" width={'100%'} height={'1000px'} style={{ zIndex: 1 }} />
      </div>
      <div className="text-bold text-center" style={{ position: 'relative', top: -400 }}>
        {status}
      </div>
      <div
        className="text-bold danger-color text-center"
        dangerouslySetInnerHTML={{ __html: errorText }}
      ></div>
    </div>
  );
};

export default PaymentPage;
