import React, { FC, useEffect, useState } from 'react';
import { PackageDetails, Room } from '../ts/interfaces/PackageDetails';
import SummaryCard from '../components/Details/SummaryCard';
import FlightCardSmall from '../components/Book/FlightCardSmall';
import { getDetailsParams } from '../services/packageService';
import { useParams } from 'react-router-dom';
import PassengerInfo from '../components/Book/PassengerInfo';
import { BookDetails, BookPayment } from '../ts/interfaces/Book';
import PassengersInfo from '../components/Book/PassengersInfo';
import RoomsSummary from '../components/Book/RoomsSummary';
import BookSkeleton from '../components/Skeleton/BookSkeleton';
import { getBookDetails } from '../services/skiService';
import { book as bookSki } from '../services/skiService';

const BookSkiPage: FC = () => {
  const params = useParams();
  const [details, setDetails] = useState<PackageDetails>();
  const [book, setBook] = useState<BookDetails>();
  const [choosenRooms, setChoosenRooms] = useState<Room[]>([]);
  const [bookPayment, setBookPayment] = useState<BookPayment>();
  const [firstStep, setFirstStep] = useState(true);

  useEffect(() => {
    (async () => {
      const detailsParams: getDetailsParams = {
        checkIn: params.checkIn!,
        city: params.city!,
        dealId: params.dealId!,
        hotelName: params.hotelName!,
        nights: params.nights!,
        packageId: params.packageId!,
        room: params.room!,
        isSale: params.isSale || 'false',
      };
      const newDetails: PackageDetails = JSON.parse(localStorage.getItem('details')!);
      setDetails(newDetails);

      const choosenRoomsFromUrl = params.room!.split(',').reduce((acc: any, room) => {
        const roomSplit = room.split('-');

        const roomFromUrl = {
          roomCode: roomSplit[0],
          roomType: roomSplit[1],
          count: parseFloat(roomSplit[2]),
        };
        const allRooms = newDetails.Rooms.reduce(
          (acc: Room[], room) => [...acc, ...room.Rooms],
          [],
        );

        const choosenRoom = allRooms.find(
          (room) =>
            room.RoomCode === roomFromUrl.roomCode && room.RoomType === roomFromUrl.roomType,
        )!;

        const result = Array(roomFromUrl.count).fill(choosenRoom);

        return [...acc, ...result];
      }, []);

      setChoosenRooms(choosenRoomsFromUrl);
      localStorage.setItem('choosenRooms', JSON.stringify(choosenRoomsFromUrl));
      const newBook = await getBookDetails(detailsParams);
      setBook(newBook);
    })();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstSpepSuccess = (book: BookPayment) => {
    setFirstStep(false);
    setBookPayment(book);
  };

  return (
    <div className="details-wrapper book-wrapper">
      {details ? (
        <div className="details-first">
          {book ? (
            <div className="details-center">
              {firstStep ? (
                <PassengerInfo
                  onSuccess={onFirstSpepSuccess}
                  orderToken={book.OrderToken}
                  bookMethod={bookSki}
                />
              ) : (
                <PassengersInfo
                  bookPayment={bookPayment!}
                  orderToken={book.OrderToken}
                  isSki={true}
                />
              )}
            </div>
          ) : (
            <BookSkeleton />
          )}

          <div className="details-sidebar">
            <RoomsSummary rooms={choosenRooms} />
            <div className="details-shadow">
              <div className="rooms-table-header ticket-sm-header">פרטי טיסה</div>
              <FlightCardSmall
                flight={details.Flight.Departure}
                returnFlight={details.Flight.Arrival}
              />
              <FlightCardSmall
                flight={details.Flight.ReturnDeparture}
                returnFlight={details.Flight.ReturnArrival}
              />
            </div>
            <SummaryCard details={details} />
          </div>
        </div>
      ) : (
        <div>אין פרטים</div>
      )}
    </div>
  );
};

export default BookSkiPage;
