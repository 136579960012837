import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@mui/material';
import React from 'react';

const DetailsSkeleton = () => {
  return (
    <div className="details-wrapper">
      <div className="details-first">
        <div className="details-center">
          <div className="details-info details-shadow">
            <div className="details-header">
              <span className="name">
                <Skeleton variant="rectangular" animation="wave" height={22} width={'50%'} />
              </span>
            </div>
            <div className="image">
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={'calc(100% - 50px)'}
                width={'100%'}
              />
            </div>
          </div>
        </div>
        <div className="details-sidebar">
          <div className="details-shadow details-summary">
            <div className="details-sidebar-header">
              <span className="name">
                <Skeleton variant="rectangular" animation="wave" height={22} width={150} />
              </span>
            </div>
            <div className="details-sidebar-list">
              <div className="details-sidebar-list-item">
                <Skeleton variant="rectangular" animation="wave" height={16.5} width={'80%'} />
              </div>
              <div className="details-sidebar-list-item">
                <Skeleton variant="rectangular" animation="wave" height={16.5} width={'80%'} />
              </div>
              <div className="details-sidebar-list-item">
                <Skeleton variant="rectangular" animation="wave" height={16.5} width={'70%'} />
              </div>
              <div className="details-sidebar-list-item">
                <Skeleton variant="rectangular" animation="wave" height={16.5} width={'30%'} />
              </div>
              <div className="details-sidebar-list-item">
                <Skeleton variant="rectangular" animation="wave" height={16.5} width={'50%'} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="details-title">
        <Skeleton variant="rectangular" animation="wave" height={20} width={200} />
      </div>
      <TicketSkeleton />
      <TicketSkeleton />
    </div>
  );
};

const TicketSkeleton = () => {
  return (
    <div className="details-shadow">
      <div className="ticket">
        <div className="ticket-header">
          <span>
            <Skeleton variant="rectangular" animation="wave" height={16} width={100} />
          </span>
        </div>
        <div className="ticket-logo">
          <div className="ticket-logo-img">
            <Skeleton variant="rectangular" animation="wave" height={'100%'} width={'100%'} />
          </div>
          <Skeleton variant="rectangular" animation="wave" height={13} width={40} />
        </div>
        <div className="ticket-route">
          <div className="city">
            <Skeleton variant="rectangular" animation="wave" height={15} width={80} />
          </div>
          <div className="time">
            <Skeleton variant="rectangular" animation="wave" height={20} width={50} />
          </div>
          <div className="date">
            <Skeleton variant="rectangular" animation="wave" height={13} width={100} />
          </div>
        </div>
        <div className="ticket-separator">
          <span className="type-flight">
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={13}
              width={100}
              sx={{ display: 'inline-block' }}
            />
          </span>
          <span className="circle"></span>
          <FontAwesomeIcon icon={faPlane} />
        </div>
        <div className="ticket-route">
          <div className="city">
            <Skeleton variant="rectangular" animation="wave" height={15} width={80} />
          </div>
          <div className="time">
            <Skeleton variant="rectangular" animation="wave" height={20} width={50} />
          </div>
          <div className="date">
            <Skeleton variant="rectangular" animation="wave" height={13} width={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSkeleton;
