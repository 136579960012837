import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPage, getSkiPage } from '../services/contentService';
import { insertScript, insertMeta } from '../services/utilService';
import { Page } from '../ts/interfaces/Page';

type PageType = 'tour' | 'ski' | 'index';

export const useInitPage = () => {
  const location = useLocation();
  const [activePageType, setActivePageType] = useState<PageType>();
  const [alreadyInited, setAlreadyInited] = useState(false);

  useEffect(() => {
    let newActivePageType: PageType;
    let path: string;
    const notIncludePages =
      !location.pathname.includes('details') &&
      !location.pathname.includes('success') &&
      !location.pathname.includes('payment') &&
      !location.pathname.includes('book');
    const isTour = location.pathname.includes('tour') && notIncludePages;
    const isSki = location.pathname.includes('ski') && notIncludePages;

    if (isTour) {
      newActivePageType = 'tour';
      path = location.pathname.split('/')[2];

      if (location.pathname.includes('results')) {
        initPage('index');
      } else {
        initPage(path);
      }
    } else if (isSki) {
      newActivePageType = 'ski';
      path = location.pathname.split('/')[2];

      if (location.pathname.includes('results')) {
        initSkiPage('index');
      } else {
        initSkiPage(path);
      }
    } else {
      newActivePageType = 'index';
      path = 'index';

      if (activePageType !== newActivePageType) {
        initPage(path);
      }
    }
    setActivePageType(newActivePageType);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const initPage = async (path: string) => {
    localStorage.removeItem('page');
    const page = await getPage(path);

    initScriptsAndMeta(page);

    localStorage.setItem('page', JSON.stringify(page));
  };

  const initSkiPage = async (path: string) => {
    localStorage.removeItem('page');
    const page = await getSkiPage(path);

    initScriptsAndMeta(page);

    localStorage.setItem('page', JSON.stringify(page));
  };

  const initScriptsAndMeta = (page: Page) => {
    const desc = page.Page.Languages[0].SEO.Title
      ? page.Page.Languages[0].SEO.Title
      : page.Page.Languages[0].Title.Desc;

    if (desc) {
      document.title = desc + ' - Ophir Tours';
    }

    if (!alreadyInited) {
      insertScript(page.SiteConfig.GoogleTagManager);
      insertScript(page.SiteConfig.AnotherAnalytics);
      insertScript(page.SiteConfig.Script1);
      insertScript(page.SiteConfig.Script2);
      insertScript(page.SiteConfig.Script3);

      const metaTitle = desc;
      insertMeta('Title', metaTitle);
      insertMeta('Description', page.Page.Languages[0].SEO.Description);
      insertMeta('Keywords', page.Page.Languages[0].SEO.Keywords);
      insertMeta('Canonical', page.Page.Languages[0].SEO.Canonical);

      setAlreadyInited(true);
    }
  };
};
