import { FC, Fragment } from 'react';
import DisplayGroup from '../Ui/DisplayGroup';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';
import { getClassNameByStatus, getTextByStatus } from '../../services/utilService';

interface HotelCardSuccessProps {
  hotelStatus?: string;
}

const HotelCardSuccess: FC<HotelCardSuccessProps> = ({ hotelStatus }) => {
  const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);

  return (
    <div className="details-shadow book-passenger">
      <div className="details-header">
        <div className="name">פרטי מלון </div>
      </div>
      <div className="form-wrapper">
        <DisplayGroup value={details.Hotel.Name} label="שם" className="col-12 col-md-6" />
        <DisplayGroup
          value={details.Hotel.StarRating + ' כוכבים'}
          label="דירוג"
          className="col-12 col-md-6"
        />
        <DisplayGroup
          value={details.Hotel.CheckIn}
          label="תאריך כניסה"
          className="col-12 col-md-6"
        />
        <DisplayGroup
          value={details.Hotel.CheckOut}
          label="תאריך יציאה"
          className="col-12 col-md-6"
        />
        <DisplayGroup
          value={details.Hotel.NightsCount.toString()}
          label="לילות"
          className="col-12 col-md-6"
        />
        <DisplayGroup
          value={details.Rooms.length.toString()}
          label="חדרים"
          className="col-12 col-md-6 mb-20"
        />

        {hotelStatus && (
          <Fragment>
            <div className="col-12 text-bold">HotelStatus</div>
            <div className="col-12 success-ticket-ok">
              <span>{details.Hotel.Name}</span>
              <span className={getClassNameByStatus(hotelStatus)}>
                {getTextByStatus(hotelStatus)}
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default HotelCardSuccess;
