import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { Accessibility } from './Accessibility';
import { useGetPage } from '../hooks/useGetPage';
import { useInitPage } from '../hooks/useInitPage';
import { logOut } from '../services/contentService';

const Header = () => {
  useInitPage();
  const page = useGetPage();
  const [openPageName, setOpenPageName] = useState('');
  const [user, setUser] = useState<any>();
  const location = useLocation();

  const onPressHanburger = () => {
    document.querySelector('.header-fields')!.classList.toggle('open');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      const user = sessionStorage.getItem('userData');
      if (user) {
        setUser(JSON.parse(user));
      }
    }
  }, [location]);

  const signOut = async () => {
    await logOut();
    sessionStorage.removeItem('userData');
    setUser(null);
  };

  return (
    <div className="header">
      <Link to={'/'}>
        <img className="header-logo" src={require('../assets/images/OphirLogo.png')} alt="Logo" />
      </Link>

      <span className="hamburger">
        <input id="hamburger__toggle" type="checkbox" onClick={onPressHanburger} />
        <label className="hamburger__btn" htmlFor="hamburger__toggle">
          <span></span>
        </label>
      </span>
      <div className="header-fields">
        {page?.SiteMenuHe.MainPages.map((item, index) => (
          <div
            className="header-cell"
            style={{ order: item.OrderDisplay }}
            key={index}
            onMouseLeave={() => setOpenPageName('')}
          >
            <Link
              to={item.Url}
              className="header-cell-text"
              onMouseEnter={() => setOpenPageName(item.Title)}
            >
              {item.Title}
              {item.SubPages?.length ? <FontAwesomeIcon icon={faChevronDown} /> : ''}
            </Link>

            <div className={'header-cell-dropdown ' + (item.Title === openPageName ? 'open' : '')}>
              {item.SubPages?.map((subItem, index) => (
                <Link key={index} to={subItem.Url} style={{ order: subItem.OrderDisplay }}>
                  {subItem.Title}
                </Link>
              ))}
            </div>
          </div>
        ))}
        {!user && (
          <div className="header-cell" style={{ order: 100 }}>
            <Link to={'signIn'} className="header-cell-text">
              התחברות
            </Link>
          </div>
        )}
        {!!user && (
          <div className="header-cell" style={{ order: 100 }}>
            <Link to={'/'} className="header-cell-text" onClick={signOut}>
              להתנתק <FontAwesomeIcon icon={faUser} /> {`${user.User_f_name} ${user.User_l_name}`}
            </Link>
          </div>
        )}
      </div>
      <span className="header-switch-theme">
        <Accessibility />
      </span>
    </div>
  );
};

export default Header;
