export const sxLinearProgress = {
  width: '100%',
  backgroundColor: 'var(--lightest-text-color)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'var(--contrast-text-color)',
  },
};

export const sxSearchDropdown = {
  backgroundColor: 'var(--default-bg-color)',
  color: 'var(--main-text-color)',
  margin: '50px 0 0 -25px',
  padding: '0 10px',
  maxHeight: '300px',
};

export const sxDefaultDropdown = {
  backgroundColor: 'var(--default-bg-color)',
  color: 'var(--main-text-color)',
  maxHeight: '300px',
};

export const sxSearchSelect = {
  backgroundColor: 'var(--default-bg-color)',
  color: 'var(--contrast-text-color)',
  border: 'none',
  outline: 'none',
  fontSize: '16px',
  padding: '0',
};

export const sxSearchInput = {
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  fontWeight: 600,
  zIndex: 1,
  position: 'relative',
  outline: 'none',
};

export const sxDefSelect = {
  backgroundColor: 'var(--default-bg-color)',
  color: 'var(--contrast-text-color)',
  border: 'none',
  outline: 'none',
  fontSize: '16px',
  padding: '0',
};

export const sxDefInput = {
  padding: 4,
  border: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
};

export const sxDisplayIb = { display: 'inline-block' };
