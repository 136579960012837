import { FC, Fragment, useEffect, useState } from 'react';
import SinlgeSale from '../components/Sales/SingleSale';
import LandingOneSkeleton from '../components/Skeleton/LandingOneSkeleton';
import { getLanding } from '../services/contentService';
import { useParams } from 'react-router-dom';
import MainCarousel from '../components/Main/MainCarousel';

const LandingOne: FC = () => {
  const [page, setPage] = useState<any>();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setPage(await getLanding(params.name!));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {page ? (
        <div className="display-flex">
          {!!page?.Carousel?.ListOfGalleryId.length && (
            <MainCarousel
              onSubmit={() => {}}
              order={page?.OrdersDisplay.Carousel || 0}
              listOfGalleryId={page?.Carousel?.ListOfGalleryId}
            />
          )}

          <div style={{ order: page.OrdersDisplay.PageTabs }} className="main-text">
            {page.Tabs.map((tab: any) => (
              <SinlgeSale tab={tab.Title} image={tab.ListOfGalleryId[0].Code} />
            ))}
          </div>
          {page.PageContent.map((content: any, index: number) => (
            <div
              key={index}
              style={{ order: page.OrdersDisplay.PageContent }}
              className="main-text"
            >
              <div className="main-title">{content.Desc}</div>
              <div dangerouslySetInnerHTML={{ __html: content.Body! }} />
            </div>
          ))}
        </div>
      ) : (
        <LandingOneSkeleton />
      )}
    </Fragment>
  );
};

export default LandingOne;
