import MainCarousel from '../components/Main/MainCarousel';
import Sales from '../components/Sales/Sales';
import { useGetPage } from '../hooks/useGetPage';
import PageContent from '../components/Main/PageContent';
import Banners from '../components/Main/Banners';

const MainPage = () => {
  const page = useGetPage();

  return (
    <div className="display-flex">
      <MainCarousel
        onSubmit={() => {}}
        order={page?.OrdersDisplay.Carousel || 0}
        listOfGalleryId={page?.Carousel?.ListOfGalleryId || []}
      />
      <PageContent pageContent={page?.PageContent} order={page?.OrdersDisplay.PageContent || 100} />
      <Banners banners={page?.Banners} order={page?.OrdersDisplay.Banners || 100} />

      <Sales order={page?.OrdersDisplay.Sales || 100} />
    </div>
  );
};

export default MainPage;
