import { FC, Fragment, useEffect } from 'react';
import { formatPrice } from '../../services/utilService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { PaxModel } from '../../ts/classes/PaxModel';
import Checkbox from '../Ui/Checkbox';
import { RoomModel } from '../../ts/classes/RoomModel';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { includeOrNotRadio } from '../../constants/generic';

interface SupplimentsProps {
  pax: PaxModel;
  paxIndex: number;
  roomIndex: number;
  rooms: RoomModel[];
  currencySymbol: string;
  onChange: (rooms: RoomModel[], totalPrice: number) => void;
  disabled?: boolean;
}

const Suppliments: FC<SupplimentsProps> = (props) => {
  const radioSx = {
    color: '#9CA3AF',
    '&.Mui-checked': {
      color: '#003654',
    },
  };

  useEffect(() => {
    const totalPrice = getTotalPrice(props.rooms);

    props.onChange(props.rooms, totalPrice);

    const kmslIndex = props.rooms[props.roomIndex].paxes[props.paxIndex].suppliments.findIndex(
      (sup) => JSON.stringify(sup).includes('KSML'),
    );
    onSelectSupplimentRadio(includeOrNotRadio[0].value, kmslIndex);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectSuppliment = (checked: boolean, index: number) => {
    const newRooms = [...props.rooms];
    newRooms[props.roomIndex].paxes[props.paxIndex].suppliments[index].checked = checked;

    const totalPrice = getTotalPrice(newRooms);

    props.onChange(newRooms, totalPrice);
  };

  const onSelectSupplimentRadio = (value: string, index: number) => {
    console.log('value', value);
    console.log('index', index);
    const newRooms = [...props.rooms];
    newRooms[props.roomIndex].paxes[props.paxIndex].suppliments[index].radioValue = value;

    const totalPrice = getTotalPrice(newRooms);

    props.onChange(newRooms, totalPrice);
  };

  const getTotalPrice = (newRooms: RoomModel[]) => {
    return newRooms.reduce((accRoom, room) => {
      const priceRoom = room.paxes.reduce((accPax, pax) => {
        const pricePax = pax.suppliments
          .filter((sup) =>
            JSON.stringify(sup).includes('KSML') ? sup.radioValue === '1' : sup.checked,
          )
          .reduce((accSup, sup) => accSup + sup.price, 0);
        return accPax + pricePax;
      }, 0);
      return accRoom + priceRoom;
    }, 0);
  };

  return (
    <div className="book-passenger-suppliments">
      {!!props.pax.suppliments.length && (
        <div className="col-12 mb-10">פעילויות לבחירה בתשלום:</div>
      )}
      {props.pax.suppliments.map((suppliment, supplimentIndex) => (
        <Fragment key={supplimentIndex}>
          {JSON.stringify(suppliment).includes('KSML') ? (
            <div className="col-12 book-passenger-suppliment" key={supplimentIndex}>
              <div className="ksml">
                <div className="danger-color mb-10">
                  * האם את.ה מעוניינ.ת בארוחות כשרות (בית חב"ד) במהלך הסמינר?
                </div>
                <div>
                  <FontAwesomeIcon className="ml-4" icon={faUtensils} /> {suppliment.nameLoc}
                  <span className="text-bold mw-75">
                    {' — '}
                    {formatPrice(suppliment.price, props.currencySymbol)}
                  </span>
                </div>
                <RadioGroup
                  value={suppliment.radioValue}
                  onChange={(_, value) => onSelectSupplimentRadio(value, supplimentIndex)}
                  defaultValue={includeOrNotRadio[0].value}
                >
                  <FormControlLabel
                    disabled={props.disabled}
                    value={includeOrNotRadio[1].value}
                    control={<Radio sx={radioSx} />}
                    label={includeOrNotRadio[1].label}
                  />
                  <FormControlLabel
                    disabled={props.disabled}
                    value={includeOrNotRadio[0].value}
                    control={<Radio sx={radioSx} />}
                    label={includeOrNotRadio[0].label}
                  />
                </RadioGroup>
              </div>
            </div>
          ) : (
            <div className="col-12 book-passenger-suppliment" key={supplimentIndex}>
              <div className="ml-4">
                <Checkbox
                  disabled={props.disabled}
                  label={suppliment.nameLoc}
                  value={suppliment.checked}
                  onSelect={(checked) => onSelectSuppliment(checked, supplimentIndex)}
                  children={<FontAwesomeIcon className="ml-4" icon={faSnowflake} />}
                />
              </div>

              <span className="text-bold mw-75">
                {' — '}
                {formatPrice(suppliment.price, props.currencySymbol)}
              </span>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Suppliments;
