import React, { FC, useEffect, useState } from 'react';
import { getSales } from '../../services/contentService';
import { Sale } from '../../ts/interfaces/Sale';
import SaleCard from './SaleCard';
import SalesSkeleton from '../Skeleton/SalesSkeleton';
import { useLocation, useParams } from 'react-router-dom';

interface SalesProps {
  order?: number;
}

const Sales: FC<SalesProps> = ({ order }) => {
  const [sales, setSales] = useState<Sale[]>();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setSales(undefined);
    (async () => {
      if (location.pathname === '/') {
        setSales(await getSales('index', 'tour'));
      } else if (location.pathname.includes('tour')) {
        setSales(await getSales(params.tourpath!, 'tour'));
      } else if (location.pathname.includes('ski')) {
        setSales(await getSales(params.skipath!, 'ski'));
      }
    })();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ order }}>
      {!!(sales && sales.length) && (
        <div className="sales">
          <div className="sales-title">מבצעים</div>
          <div className="sales-cards">
            {sales.map((sale, index) => (
              <SaleCard card={sale} key={index} />
            ))}
          </div>
        </div>
      )}

      {!sales && <SalesSkeleton />}
    </div>
  );
};

export default Sales;
