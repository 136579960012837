import React from 'react';
import Router from './routers';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { reCaptchaKey } from './constants/path';

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
          <Router />
        </GoogleReCaptchaProvider>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
