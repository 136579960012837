import { PaxModel, Suppliment } from './PaxModel';

export class PassengerRequest {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  PaxCode: number;
  PaxTypeCode: string;
  Param_1: string;
  suppliments: Suppliment[];

  constructor(pax: PaxModel) {
    this.FirstName = pax.firstName;
    this.LastName = pax.lastName;
    this.Phone = pax.phone;
    this.Email = pax.email;
    this.PaxCode = pax.paxCode;
    this.PaxTypeCode = pax.typeDescription;
    this.Param_1 = pax.additionalInfo;

    this.suppliments = pax.suppliments;
  }
}
