import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, SyntheticEvent } from 'react';
import { FlightPart } from '../../ts/interfaces/Flight';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getClassNameByStatus, getTextByStatus } from '../../services/utilService';

interface FlightCardProps {
  flight: FlightPart;
  returnFlight: FlightPart;
  icon: IconProp;
  title: string;
  success?: boolean;
  flightStatus?: string;
}

const FlightCard: FC<FlightCardProps> = ({
  flight,
  returnFlight,
  title,
  icon,
  success,
  flightStatus,
}) => {
  const onImageLoadError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = require('../../assets/images/airplane.png');
  };
  return (
    <div className="details-shadow">
      <div className={'ticket ' + (success ? 'ticket-success' : '')}>
        <div className="ticket-header">
          <FontAwesomeIcon icon={icon} />
          <span>{title}</span>
        </div>
        <div className="ticket-logo">
          <div className="ticket-logo-img">
            <img src={flight.AirlineLogo} alt="logo" onError={onImageLoadError} />
          </div>
          <span className="name">{flight.AirlineDesc}</span>
        </div>
        <div className="ticket-route">
          <div className="city">
            {flight.CityName} - <span className="code">{flight.CityCode}</span>
          </div>
          <div className="time">{flight.Time}</div>
          <div className="date"> {flight.Date}</div>
        </div>
        <div className="ticket-separator">
          <span className="type-flight">טיסה בשכר</span>
          <span className="circle"></span>
          <FontAwesomeIcon icon={faPlane} />
        </div>
        <div className="ticket-route">
          <div className="city">
            {returnFlight.CityName} - <span className="code">{returnFlight.CityCode}</span>
          </div>
          <div className="time">{returnFlight.Time}</div>
          <div className="date"> {returnFlight.Date}</div>
        </div>
        {success && flightStatus && (
          <div className="ticket-ok">
            <span className={getClassNameByStatus(flightStatus)}>
              {getTextByStatus(flightStatus)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlightCard;
