import { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import SearchMain from '../Search/SearchMain';
import { sitePath } from '../../constants/path';
import { ListOfGalleryId } from '../../ts/interfaces/Page';

interface MainCarouselProps {
  onSubmit: () => void;
  className?: string;
  order?: number;
  listOfGalleryId: ListOfGalleryId[];
}
const MainCarousel: FC<MainCarouselProps> = ({ onSubmit, className, order, listOfGalleryId }) => {
  return (
    <div className={'main-carousel-wr ' + className} style={{ order }}>
      <div className="carousel">
        <Carousel autoPlay={true} indicators={false} interval={10000}>
          {listOfGalleryId.map((gallery, index) => (
            <img className={'main-image-wr '} src={sitePath + gallery.Code} key={index} alt="" />
          ))}
        </Carousel>
      </div>
      <SearchMain onSubmit={onSubmit} />
    </div>
  );
};

MainCarousel.defaultProps = {
  className: '',
};

export default MainCarousel;
