import { serverPath } from '../constants/path';
import { PassengerRequest } from '../ts/classes/PassenferRequest';
import { PassengerInfoModel } from '../ts/classes/PassengerInfoModel';
import { PaxModel } from '../ts/classes/PaxModel';
import { RoomModel } from '../ts/classes/RoomModel';
import { BookDetails, BookPayment, PayBook, PaymentCheck } from '../ts/interfaces/Book';
import { PackageDetails } from '../ts/interfaces/PackageDetails';
import { getStrDate } from './utilService';
import { AutocompleteItem, Credit2000PaymentInfo } from '../ts/interfaces/Generic';

export interface getDetailsParams {
  checkIn: string;
  city: string;
  dealId: string;
  hotelName: string;
  nights: string;
  packageId: string;
  room: string;
  token?: string;
  isSale?: string;
}

export const getDetails = async (params: getDetailsParams): Promise<PackageDetails> => {
  let url = `details/${params.hotelName}/${params.city}/${params.checkIn}/${params.nights}/${params.dealId}/${params.packageId}/${params.room}/tour`;
  if (params.token) {
    url += `/${params.token}`;
  }
  return await (await fetch(serverPath + url)).json();
};

export const getBookDetails = async (params: getDetailsParams): Promise<BookDetails> => {
  let url = `book/${params.hotelName}/${params.city}/${params.checkIn}/${params.nights}/${params.dealId}/${params.packageId}/${params.room}/tour`;
  if (params.token) {
    url += `/${params.token}`;
  }
  return await (await fetch(serverPath + url)).json();
};

export const book = async (
  orderToken: string,
  passenger: PassengerInfoModel,
): Promise<BookPayment | void> => {
  try {
    const url = `bookpayment/${orderToken}/tour`;

    const formData = new FormData();
    formData.append('OwnerFirstName', passenger.firstName);
    formData.append('OwnerLastName', passenger.lastName);
    formData.append('OwnerEmail', passenger.email);
    formData.append('OwnerPhone', passenger.phone);
    formData.append('OwnerMessage', passenger.notes);
    const params = { method: 'POST', body: formData };

    return await (await fetch(serverPath + url, params)).json();
  } catch (err) {}
};

export const pay = async (orderToken: string, rooms: RoomModel[]): Promise<PayBook> => {
  const url = 'bookcredit/' + orderToken + '/tour';
  const params = { method: 'POST', body: formatPaxData(rooms) };

  return await (await fetch(serverPath + url, params)).json();
};

export const payClosed = async (
  orderToken: string,
  rooms: RoomModel[],
  credit: Credit2000PaymentInfo,
): Promise<PayBook> => {
  const url = 'bookcreditclosed/' + orderToken + '/tour';

  const formData = new FormData();
  const dataArr = rooms.reduce((acc: PaxModel[], room) => {
    return acc.concat(room.paxes);
  }, []);

  formData.append(
    'Credit2000PaymentSettings.NumberOfCreditPayments',
    credit.NumberOfCreditPayments.toString(),
  );
  formData.append(
    'Credit2000PaymentSettings.NumberOfRegularPayments',
    credit.NumberOfRegularPayments.toString(),
  );
  formData.append(
    'Credit2000PaymentSettings.TypeOfTransaction',
    credit.TypeOfTransaction.toString(),
  );

  dataArr.forEach((pax, index) => {
    formData.append('Paxes[' + index + '].FirstName', pax.firstName);
    formData.append('Paxes[' + index + '].LastName', pax.lastName);
    formData.append('Paxes[' + index + '].DOB', getStrDate(pax.birthDate));
    formData.append(
      'Paxes[' + index + '].PassportValidTill',
      getStrDate(pax.passportExpirationDate),
    );
    formData.append('Paxes[' + index + '].Citizenship', pax.nationality);
    formData.append('Paxes[' + index + '].Address', pax.address);
    formData.append('Paxes[' + index + '].Phone', pax.phone);
    formData.append('Paxes[' + index + '].Email', pax.email);
    formData.append('Paxes[' + index + '].VisaFatherName', pax.fathersName);
    formData.append('Paxes[' + index + '].VisaMotherName', pax.mothersName);
    formData.append('Paxes[' + index + '].VisaCountry', pax.birthCountry);

    formData.append('Paxes[' + index + '].PaxCode', pax.paxCode.toString());
    formData.append('Paxes[' + index + '].PaxTypeCode', pax.typeDescription);
    formData.append('Paxes[' + index + '].Param_1', pax.additionalInfo);
    formData.append('Paxes[' + index + '].PassportNumber', pax.passportNumber);
    formData.append('Paxes[' + index + '].Tz', pax.tz);

    pax.suppliments.forEach((sup, supIndex) => {
      const append = (key: string, value: string) => {
        formData.append('Paxes[' + index + '].Suppliments[' + supIndex + '].' + key, value);
      };

      append('Price', sup.price.toString());
      append('GenId', sup.genId.toString());
      append('AgeFrom', sup.ageFrom.toString());
      append('AgeTo', sup.ageTo.toString());
      append('SupplimentID', sup.supplimentID.toString());
      append('Type', sup.type);
      append('Require', sup.require.toString());
      append('Include', sup.include.toString());
      append('Shift', sup.shift.toString());
      append('Duration', sup.duration.toString());
      append('Remark', sup.remark);
      append('Name', sup.name);
      append('NameLoc', sup.nameLoc);
      append('ApplyCode', sup.applyCode);
      append('Supplier', sup.supplier);
      append('SupplierCC', sup.supplierCC);
      append('Service', sup.service);
      append('IsTranserService', sup.isTranserService.toString());

      if (JSON.stringify(sup).includes('KSML')) {
        append('IsPicked', sup.radioValue === '1' ? 'true' : 'false');
      } else {
        append('IsPicked', sup.checked.toString());
      }
    });
  });

  const params = { method: 'POST', body: formData };

  return await (await fetch(serverPath + url, params)).json();
};

export const request = async (orderToken: string, rooms: RoomModel[]): Promise<PaymentCheck> => {
  const url = 'bookrequest/' + orderToken + '/tour';
  const params = { method: 'POST', body: formatPaxData(rooms) };

  return await (await fetch(serverPath + url, params)).json();
};

export const checkPaymentStatus = async (
  orderToken: string,
  type: string,
): Promise<PaymentCheck> => {
  const url = `bookcreditstatus/${orderToken}/${type}`;

  return await (await fetch(serverPath + url, { method: 'POST' })).json();
};

export const loginToPackage = async (
  id: string,
  password: string,
  startdate: string,
): Promise<string> => {
  const url = `logintopackage/${id}/${password}/${startdate}`;

  return await (await fetch(serverPath + url, { method: 'POST' })).json();
};

export const getNationalities = async (): Promise<AutocompleteItem[]> => {
  try {
    const url = 'nationality';

    return (await (await fetch(serverPath + url)).json()).map((item: any) => ({
      label: item.Text,
      value: item.Value,
    }));
  } catch (err) {
    return [];
  }
};

const formatPaxData = (rooms: RoomModel[]) => {
  const dataArr = rooms.reduce((acc: PassengerRequest[], room) => {
    return acc.concat(room.paxes.map((pax) => new PassengerRequest(pax)));
  }, []);

  const data = new FormData();
  dataArr.forEach((pax, index) => {
    data.append('Paxes[' + index + '].FirstName', pax.FirstName);
    data.append('Paxes[' + index + '].LastName', pax.LastName);
    data.append('Paxes[' + index + '].Phone', pax.Phone);
    data.append('Paxes[' + index + '].Email', pax.Email);
    data.append('Paxes[' + index + '].PaxCode', pax.PaxCode.toString());
    data.append('Paxes[' + index + '].PaxTypeCode', pax.PaxTypeCode);
    data.append('Paxes[' + index + '].Param_1', pax.Param_1);

    pax.suppliments.forEach((sup, supIndex) => {
      const append = (key: string, value: string) => {
        data.append('Paxes[' + index + '].Suppliments[' + supIndex + '].' + key, value);
      };

      append('Price', sup.price.toString());
      append('GenId', sup.genId.toString());
      append('AgeFrom', sup.ageFrom.toString());
      append('AgeTo', sup.ageTo.toString());
      append('SupplimentID', sup.supplimentID.toString());
      append('Type', sup.type);
      append('Require', sup.require.toString());
      append('Include', sup.include.toString());
      append('Shift', sup.shift.toString());
      append('Duration', sup.duration.toString());
      append('Remark', sup.remark);
      append('Name', sup.name);
      append('NameLoc', sup.nameLoc);
      append('ApplyCode', sup.applyCode);
      append('Supplier', sup.supplier);
      append('SupplierCC', sup.supplierCC);
      append('Service', sup.service);
      append('IsTranserService', sup.isTranserService.toString());

      if (JSON.stringify(sup).includes('KSML')) {
        append('IsPicked', sup.radioValue === '1' ? 'true' : 'false');
      } else {
        append('IsPicked', sup.checked.toString());
      }
    });
  });

  return data;
};
