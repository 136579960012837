import React, { FC, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { formatPaxType } from '../../services/utilService';
import { RoomModel } from '../../ts/classes/RoomModel';
import DisplayGroup from '../Ui/DisplayGroup';
import Suppliments from '../Book/Suppliments';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';

const PassengersListSuccess: FC = () => {
  const rooms: RoomModel[] = JSON.parse(localStorage.getItem('rooms')!);
  const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);

  return (
    <Fragment>
      {rooms.map((room, roomIndex) => (
        <Fragment key={roomIndex}>
          <div className="book-passenger-header">
            {room.paxes.map((_pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <FontAwesomeIcon icon={faUser} />{' '}
              </Fragment>
            ))}
            {room.description}
            <div className="text-bold">{room.classDescription}</div>
          </div>
          <div className="form-wrapper">
            {room.paxes.map((pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <div className="col-12 mb-10 text-bold">
                  <FontAwesomeIcon icon={faUser} /> {formatPaxType(pax.typeDescription)}{' '}
                  {paxIndex + 1}
                </div>

                <DisplayGroup value={pax.firstName} label="שם פרטי" className="col-12 col-md-6" />
                <DisplayGroup value={pax.lastName} label="שם משפחה" className="col-12 col-md-6" />
                {paxIndex === 0 && roomIndex === 0 && (
                  <Fragment>
                    <DisplayGroup value={pax.email} label='דוא"ל' className="col-12 col-md-6" />
                    <DisplayGroup value={pax.phone} label="טלפון" className="col-12 col-md-6" />
                  </Fragment>
                )}

                <Suppliments
                  pax={{ ...pax, suppliments: pax.suppliments.filter((sup) => sup.checked) }}
                  paxIndex={paxIndex}
                  roomIndex={roomIndex}
                  rooms={rooms}
                  currencySymbol={details.Rooms[0].CurrencySymbol}
                  onChange={() => {}}
                  disabled={true}
                />
              </Fragment>
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PassengersListSuccess;
