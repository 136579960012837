export const formatPrice = (input: number, currency?: string): string => {
  const strInput = input.toLocaleString('en-US') + (currency || '');

  return strInput;
};

export const roomTypeFilter = (input: string): string => {
  if (input === 'STD') {
    return 'תקנה';
  }

  return input;
};

export const formatPaxType = (input: string): string => {
  if (input === 'ADT') {
    return 'מבוגר';
  } else if (input === 'CNN') {
    return 'ילד';
  }

  return input;
};

export const setButtonClasses = (className: string, disabled: boolean): string => {
  if (disabled) {
    return `${className} button-disabled`;
  }

  return className;
};

export const cutPaymentUrl = (input: string) => {
  return input.substring(input.indexOf('"') + 1, input.lastIndexOf('"'));
};

export const getStrDate = (date: Date | null): string => {
  if (date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    return `${date.getFullYear()}-${month}-${day}`;
  }

  return '';
};

export const formatDateStrToStr = (date: string | Date): string => {
  if (date) {
    const dateObj = new Date(date);

    const day = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
    const month =
      dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1;
    return `${day}/${month}/${dateObj.getFullYear()}`;
  }

  return '';
};

export const insertScript = (source: string | null): void => {
  if (source) {
    const script = source
      .replaceAll('&amp;', '&')
      .replaceAll('&apos;', "'")
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&quot;', '"');

    if (script.includes('<script>')) {
      const startScript = '<script>';
      const startIndex = script.indexOf(startScript) + startScript.length;
      const endIndex = script.indexOf('</script>');
      const func = script.substring(startIndex, endIndex);

      const resultScript = document.createElement('script');
      resultScript.appendChild(document.createTextNode(func));
      document.head.appendChild(resultScript);
    } else if (script.includes('<noscript>')) {
      const startScript = '<noscript>';
      const startIndex = script.indexOf(startScript) + startScript.length;
      const endIndex = script.indexOf('</noscript>');
      const func = script.substring(startIndex, endIndex);

      const resultScript = document.createElement('noscript');
      resultScript.appendChild(document.createTextNode(func));
      document.head.appendChild(resultScript);
    }
  }
};

export const insertMeta = (name: string, content: string): void => {
  const meta = document.createElement('meta');
  meta.name = name;
  meta.content = content;
  document.head.appendChild(meta);
};

export const getObjDate = (date?: string): Date => {
  if (date) {
    const arr = date.split('-');
    return new Date(parseFloat(arr[0]), parseFloat(arr[1]) - 1, parseFloat(arr[2]));
  } else {
    return new Date();
  }
};

export const changeDateFormatToSlash = (date: string): string => {
  const arr = date.split('-');
  return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export const getClassNameByStatus = (status?: string) => {
  if (status === 'OK') return 'ok';
  if (status === 'XX') return 'err';
  return 'warning';
};

export const getTextByStatus = (status?: string) => {
  if (status === 'OK') return 'OK';
  if (status === 'XX') return 'Not Confirmed';
  return status;
};
