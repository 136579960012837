import { FC, useEffect, useState } from 'react';
import { getInfo } from '../services/contentService';
import { useParams } from 'react-router-dom';
import TextSkeleton from '../components/Skeleton/TextSkeleton';

const InfoPage: FC = () => {
  const params = useParams();
  const [info, setInfo] = useState('');

  useEffect(() => {
    (async () => {
      setInfo(await getInfo(params.page!));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInfo('');
    (async () => {
      setInfo(await getInfo(params.page!));
    })();
  }, [params.page]);

  if (info) {
    return (
      <div className="about">
        {params.page === 'Terms-And-Conditions' && <div className="main-title">תנאים והגבלות</div>}
        {params.page === 'Accessibility-Statement' && (
          <div className="main-title">הצהרת נגישות</div>
        )}
        <div dangerouslySetInnerHTML={{ __html: info }}></div>
      </div>
    );
  } else {
    return <TextSkeleton />;
  }
};

export default InfoPage;
