import React, { FC, useEffect, useState } from 'react';
import {
  faFilter,
  faList,
  faPager,
  faSort,
  faSortDown,
  faSortUp,
  faTableList,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SorterBy, SorterModel, SorterOrder } from '../../ts/interfaces/Sorter';

interface SorterProps {
  onChange: (sorter: SorterModel) => void;
}

const Sorter: FC<SorterProps> = (props) => {
  const [shortView, setShortView] = useState(false);
  const [sorter, setSorter] = useState<SorterModel>({ by: SorterBy.None, order: SorterOrder.None });

  useEffect(() => {
    if (localStorage.getItem('shortViewResults')) {
      document.querySelector('.results-list')!.classList.add('short');
      setShortView(true);
    }
  }, []);

  const onChange = (by: SorterBy) => {
    let newSorter: SorterModel;
    if (sorter.order === SorterOrder.None || sorter.by !== by) {
      newSorter = { by, order: SorterOrder.Asc };
    } else if (sorter.order === SorterOrder.Asc && sorter.by === by) {
      newSorter = { by, order: SorterOrder.Desc };
    } else {
      newSorter = { by, order: SorterOrder.Asc };
    }

    setSorter(newSorter);
    props.onChange(newSorter);
  };

  const getSorterIcon = (by: SorterBy) => {
    if (sorter.by === by && sorter.order === SorterOrder.Asc) {
      return faSortUp;
    } else if (sorter.by === by && sorter.order === SorterOrder.Desc) {
      return faSortDown;
    } else {
      return faSort;
    }
  };

  const toggleCardsListView = () => {
    if (shortView) {
      setShortView(false);
      document.querySelector('.results-list')!.classList.remove('short');
      localStorage.removeItem('shortViewResults');
    } else {
      setShortView(true);
      document.querySelector('.results-list')!.classList.add('short');
      localStorage.setItem('shortViewResults', 'true');
    }
  };

  const toggleFilter = () => document.querySelector('.results-filter')!.classList.toggle('open');

  return (
    <div className="results-sorting">
      <div className="results-sorting-item">
        <div className="results-sorting-filter content" onClick={toggleFilter}>
          <FontAwesomeIcon icon={faFilter} />
          <span className="text-default">מסנן</span>
        </div>
        {shortView ? (
          <div className="results-sorting-switcher content" onClick={toggleCardsListView}>
            <FontAwesomeIcon icon={faPager} />
            <span className="text-default">הצג בקלפים</span>
          </div>
        ) : (
          <div className="results-sorting-switcher content" onClick={toggleCardsListView}>
            <FontAwesomeIcon icon={faTableList} />
            <span className="text-default">הצג בטבלה</span>
          </div>
        )}
      </div>
      <div className="results-sorting-item" onClick={() => onChange(SorterBy.Price)}>
        <div className="content">
          <FontAwesomeIcon icon={faTag} />
          <span className="text-default">מחיר</span>
          <span className="arrow">
            <FontAwesomeIcon icon={getSorterIcon(SorterBy.Price)} />
          </span>
        </div>
      </div>
      <div className="results-sorting-item" onClick={() => onChange(SorterBy.Name)}>
        <div className="content">
          <FontAwesomeIcon icon={faList} />
          <span className="text-default">שם</span>
          <span className="arrow">
            <FontAwesomeIcon icon={getSorterIcon(SorterBy.Name)} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sorter;
