import { faTimes, faUniversalAccess } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';

interface AccessibilityModel {
  fontSize: '16px' | '18px' | '20px' | '22px';
  filter: 'contrast' | 'invert' | 'none';
  theme: 'light' | 'dark' | 'grey';
}

export const Accessibility: FC = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [accessibility, setAccessibility] = useState<AccessibilityModel>({
    fontSize: '16px',
    filter: 'none',
    theme: 'light',
  });

  useEffect(() => {
    const newAccessibility = localStorage.getItem('accessibility');
    if (newAccessibility) {
      setAccessibility(JSON.parse(newAccessibility));
    }
  }, []);

  useEffect(() => {
    document.querySelector('html')!.style.fontSize = accessibility.fontSize;
    document.body.style.fontSize = accessibility.fontSize;

    document.body.classList.remove('contrast', 'invert', 'none');
    document.body.classList.add(accessibility.filter);

    document.body.classList.remove('dark', 'light', 'grey');
    document.body.classList.add(accessibility.theme);

    localStorage.setItem('accessibility', JSON.stringify(accessibility));
  }, [accessibility]);

  const reset = () => {
    setAccessibility({
      fontSize: '16px',
      filter: 'none',
      theme: 'light',
    });
  };

  return (
    <div className="accessibility">
      <div className="accessibility-icon" onClick={() => setIsOpened(!isOpened)}>
        <FontAwesomeIcon icon={faUniversalAccess} />
      </div>

      {isOpened && (
        <div className="accessibility-form">
          <div className="accessibility-header">
            <span>
              <FontAwesomeIcon icon={faUniversalAccess} />
              הגדרות נגישות
            </span>
            <span className="accessibility-header-times" onClick={() => setIsOpened(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <div className="accessibility-options">
            <div className="accessibility-alert">
              נגישות האינטרנט אומר שאנשים עם מוגבלות יכולים להשתמש באינטרנ
            </div>
            <div className="accessibility-option">
              <span>ניגודיות:</span>
              <span
                onClick={() => setAccessibility({ ...accessibility, filter: 'invert' })}
                className={'item ' + (accessibility.filter === 'invert' ? 'active' : '')}
              >
                כהה
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, filter: 'none' })}
                className={'item ' + (accessibility.filter === 'none' ? 'active' : '')}
              >
                רגיל
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, filter: 'contrast' })}
                className={'item ' + (accessibility.filter === 'contrast' ? 'active' : '')}
              >
                בהיר
              </span>
            </div>
            <div className="accessibility-option">
              <span>צבע:</span>
              <span
                onClick={() => setAccessibility({ ...accessibility, theme: 'dark' })}
                className={'item ' + (accessibility.theme === 'dark' ? 'active' : '')}
              >
                גבוה
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, theme: 'light' })}
                className={'item ' + (accessibility.theme === 'light' ? 'active' : '')}
              >
                רגיל
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, theme: 'grey' })}
                className={'item ' + (accessibility.theme === 'grey' ? 'active' : '')}
              >
                שחור-לבן
              </span>
            </div>
            <div className="accessibility-option">
              <span>גודל פונט:</span>
              <span
                onClick={() => setAccessibility({ ...accessibility, fontSize: '16px' })}
                className={'item font-16 ' + (accessibility.fontSize === '16px' ? 'active' : '')}
              >
                Aa
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, fontSize: '18px' })}
                className={'item font-18 ' + (accessibility.fontSize === '18px' ? 'active' : '')}
              >
                Aa
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, fontSize: '20px' })}
                className={'item font-20 ' + (accessibility.fontSize === '20px' ? 'active' : '')}
              >
                Aa
              </span>
              <span
                onClick={() => setAccessibility({ ...accessibility, fontSize: '22px' })}
                className={'item font-22 ' + (accessibility.fontSize === '22px' ? 'active' : '')}
              >
                Aa
              </span>
            </div>
          </div>
          <button className="accessibility-button button-st button-colored" onClick={reset}>
            נקה בחירה
          </button>
        </div>
      )}
    </div>
  );
};
