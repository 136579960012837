export const roomsCountSelect = [
  { value: '0', label: 'בחר' },
  { value: '1', label: '1 חדר' },
  { value: '2', label: '2 חדרים' },
  { value: '3', label: '3 חדרים' },
  { value: '4', label: '4 חדרים' },
  { value: '5', label: '5 חדרים' },
  { value: '6', label: '6 חדרים' },
  { value: '7', label: '7 חדרים' },
  { value: '8', label: '8 חדרים' },
  { value: '9', label: '9 חדרים' },
];

export const includeOrNotRadio = [
  { value: '0', label: 'לא כולל' },
  { value: '1', label: 'כולל' },
];
