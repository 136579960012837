import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select, SelectChangeEvent, Skeleton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AutocompleteItem } from '../../ts/interfaces/Generic';
import { sxSearchDropdown, sxSearchSelect, sxSearchInput } from '../../constants/sx';

interface SearchSelectProps {
  data: AutocompleteItem[];
  value: string;
  icon: IconProp;
  onSelect: (item: string) => void;
  subText: string;
  lastElement?: boolean;
}

const SearchSelect: FC<SearchSelectProps> = (props) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleEvent = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    props.onSelect(event.target.value);
  };

  return (
    <div
      className={
        'search-main-cell' +
        (open ? ' search-main-cell-active' : '') +
        (props.lastElement ? ' search-main-cell-active-last search-main-cell-last' : '')
      }
      onClick={() => setOpen(!open)}
    >
      <FontAwesomeIcon icon={props.icon} className="search-icon" />
      <div className="search-main-box">
        {props.data.length ? (
          <Select
            open={open}
            className="search-select"
            value={value}
            onChange={handleEvent}
            displayEmpty
            IconComponent={() => <></>}
            sx={{ sxSearchSelect }}
            inputProps={{ sx: sxSearchInput }}
            MenuProps={{ PaperProps: { sx: sxSearchDropdown } }}
          >
            {props.data.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Skeleton variant="rectangular" animation="wave" width={110} height={24} />
        )}

        <div className="search-main-text">{props.subText}</div>
      </div>
    </div>
  );
};

export default SearchSelect;
