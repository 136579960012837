import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, TextField } from '@mui/material';
import { FC, Fragment } from 'react';

interface FilterMulticompleteProps {
  display: boolean;
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  label: string;
}

export const FilterMulticomplete: FC<FilterMulticompleteProps> = (props) => {
  return (
    <Fragment>
      {props.display && (
        <div className="filter-item">
          <span className="filter-item-title">{props.label}</span>

          <div className="filter-item-content">
            <div className="full">
              <Autocomplete
                className="MuiAutocomplete-my-multiple"
                disableCloseOnSelect
                multiple
                options={props.options}
                value={props.value}
                onChange={(_: any, value: string[] | null) => props.onChange(value as [])}
                renderInput={(params) => <TextField {...params} />}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    className={'MuiAutocomplete-my-dropdown' + (selected ? ' selected' : '')}
                  >
                    {selected && <FontAwesomeIcon icon={faCheck} />}
                    {option}
                  </li>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
