import { Skeleton } from '@mui/material';
import React, { FC } from 'react';

const BookSkeleton = () => {
  return (
    <div className="details-center">
      <form noValidate className="details-shadow book-passenger">
        <div className="details-header">
          <div className="name">
            <Skeleton variant="rectangular" animation="wave" height={22} width={300} />
          </div>
        </div>
        <div className="form-wrapper">
          <FormGroupSkeleton />
          <FormGroupSkeleton />
          <FormGroupSkeleton />
          <FormGroupSkeleton />
          <FormGroupSkeleton className="col-12" />

          <div className="col-12 book-passenger-submit">
            <Skeleton variant="rectangular" animation="wave" height={45.5} width={220} />
          </div>
        </div>
      </form>
    </div>
  );
};

const FormGroupSkeleton: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <div className="form-group">
        <label className="form-label">
          <Skeleton variant="rectangular" animation="wave" height={16} width={'30%'} />
        </label>
        <Skeleton variant="rectangular" animation="wave" height={38} width={'100%'} />
      </div>
    </div>
  );
};

FormGroupSkeleton.defaultProps = {
  className: 'col-12 col-md-6',
};

export default BookSkeleton;
