import React from 'react';
import { FC } from 'react';

interface DisplayGroupProps {
  label: string;
  value: string;
  className: string;
}

const DisplayGroup: FC<DisplayGroupProps> = (props) => {
  return (
    <div className={props.className}>
      <div className="mb-10">
        <span className="text-italic">{props.label}</span>: {props.value}
      </div>
    </div>
  );
};

export default DisplayGroup;
