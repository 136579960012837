import React, { FC, Fragment } from 'react';
import { IncludeInfo } from '../../ts/interfaces/PackageDetails';
import { faCheck, faInfo, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IncludeCardProps {
  include: IncludeInfo;
}

const IncludeCard: FC<IncludeCardProps> = ({ include }) => {
  return (
    <Fragment>
      {!!(
        include.IncludeInformation.length ||
        include.NotIncludeNames.length ||
        include.IncludeNames.length
      ) && (
        <Fragment>
          <div className="details-title">מה נכלל</div>
          <div className="details-shadow">
            {!!include.IncludeNames.length && (
              <div className="include-wr">
                <span className="title">כלול</span>
                {include.IncludeNames.map((item, index) => (
                  <div className="include item" key={index}>
                    <FontAwesomeIcon icon={faCheck} />
                    {item}
                  </div>
                ))}
              </div>
            )}

            {!!include.NotIncludeNames.length && (
              <div className="include-wr">
                <span className="title">לא כלול</span>
                {include.NotIncludeNames.map((item, index) => (
                  <div className="no-include item" key={index}>
                    <FontAwesomeIcon icon={faXmark} />
                    {item}
                  </div>
                ))}
              </div>
            )}
            {!!include.IncludeInformation.length && (
              <div className="include-wr">
                <span className="title">מידע נוסף</span>
                {include.IncludeInformation.map((item, index) => (
                  <div className="info item" key={index}>
                    <FontAwesomeIcon icon={faInfo} />
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default IncludeCard;
