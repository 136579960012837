import { FC, Fragment } from 'react';
import { sitePath } from '../../constants/path';
import { Banner } from '../../ts/interfaces/Page';

interface BannersProps {
  banners?: Banner[];
  order: number;
}
export const Banners: FC<BannersProps> = ({ banners, order }) => {
  return (
    <Fragment>
      {!!banners?.length && (
        <div style={{ order }} className="banners">
          <div className="title">מבצעים</div>
          <div className="banners-images">
            {banners?.map((banner) =>
              banner.ListOfGalleryId.map((gal) => (
                <a href={gal.ListOfLangs[0].Url} target="_blank" rel="noreferrer">
                  <img src={sitePath + gal.Code} alt="" />
                </a>
              )),
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Banners;
