import { Skeleton } from '@mui/material';
import React from 'react';

const SalesSkeleton = () => {
  return (
    <div className="sales">
      <div className="sales-title">
        <Skeleton variant="rectangular" animation="wave" width={110} height={24} />
      </div>
      <div className="sales-cards">
        {[...Array(8)].map((_, index) => (
          <div className="sales-card-wr" key={index}>
            <div className="sales-card">
              <div className="image">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={'100%'}
                  height={'100%'}
                  sx={{ borderRadius: '16px' }}
                />
              </div>
              <div className="hotel-name"></div>
              <div className="sales-card-items">
                <div className="item">
                  <Skeleton variant="rectangular" animation="wave" width={'40%'} height={15} />
                </div>
                <div className="item">
                  <Skeleton variant="rectangular" animation="wave" width={'80%'} height={15} />
                </div>
                <div className="item">
                  <Skeleton variant="rectangular" animation="wave" width={'30%'} height={15} />
                </div>
                <div className="item">
                  <Skeleton variant="rectangular" animation="wave" width={'35%'} height={15} />
                </div>
              </div>
              <div className="sales-card-footer">
                <div className="person-price">
                  <Skeleton variant="rectangular" animation="wave" width={150} height={22} />
                </div>
                <div className="total-price">
                  <Skeleton variant="rectangular" animation="wave" width={100} height={16} />
                </div>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={'100%'}
                  height={35.5}
                  sx={{ borderRadius: '6px' }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesSkeleton;
