import { FC } from 'react';

const TableViewHeader: FC = () => {
  return (
    <div className="table-header">
      <div className="content">
        <div className="title">שם</div>
        <div className="facilities">
          <div className="facility">תאריך</div>
          <div className="facility">אוכל</div>
          <div className="facility">לילות</div>
        </div>
      </div>
      <div className="table-footer">
        <span className="table-footer-price"> מחיר לאדם בחדר</span>
        <span className="table-footer-price">סה"כ</span>
      </div>
    </div>
  );
};

export default TableViewHeader;
