import { serverPath } from '../constants/path';
import { PassengerInfoModel } from '../ts/classes/PassengerInfoModel';
import { PaxModel } from '../ts/classes/PaxModel';
import { RoomModel } from '../ts/classes/RoomModel';
import { BookDetails, BookPayment, PayBook, PaymentCheck } from '../ts/interfaces/Book';
import { PackageDetails } from '../ts/interfaces/PackageDetails';
import { getDetailsParams } from './packageService';
import { getStrDate } from './utilService';

export const getDetails = async (params: getDetailsParams): Promise<PackageDetails> => {
  const url = `details/${params.hotelName}/${params.city}/${params.checkIn}/${params.nights}/${params.dealId}/${params.packageId}/${params.room}/ski`;
  return await (await fetch(serverPath + url)).json();
};

export const getBookDetails = async (params: getDetailsParams): Promise<BookDetails> => {
  const url = `bookski/${params.hotelName}/${params.city}/${params.checkIn}/${params.nights}/${params.dealId}/${params.packageId}/${params.room}/${params.isSale}`;

  return await (await fetch(serverPath + url)).json();
};

export const book = async (
  orderToken: string,
  passenger: PassengerInfoModel,
): Promise<BookPayment | void> => {
  try {
    const url = `bookpaymentski/${orderToken}`;
    const formData = new FormData();
    formData.append('OwnerFirstName', passenger.firstName);
    formData.append('OwnerLastName', passenger.lastName);
    formData.append('OwnerEmail', passenger.email);
    formData.append('OwnerPhone', passenger.phone);
    formData.append('OwnerMessage', passenger.notes);
    const params = { method: 'POST', body: formData };

    return await (await fetch(serverPath + url, params)).json();
  } catch (err) {}
};

export const paySki = async (orderToken: string, rooms: RoomModel[]): Promise<PayBook> => {
  const url = 'bookcreditski/' + orderToken;
  const params = { method: 'POST', body: formatPaxData(rooms) };

  return await (await fetch(serverPath + url, params)).json();
};

export const requestSki = async (orderToken: string, rooms: RoomModel[]): Promise<PaymentCheck> => {
  const url = 'bookrequestski/' + orderToken;
  const params = { method: 'POST', body: formatPaxData(rooms) };

  return await (await fetch(serverPath + url, params)).json();
};

const formatPaxData = (rooms: RoomModel[]) => {
  const dataArr = rooms.reduce((acc: PaxModel[], room) => {
    return acc.concat(room.paxes.map((pax) => pax));
  }, []);

  const data = new FormData();
  dataArr.forEach((pax, index) => {
    data.append('Paxes[' + index + '].FirstName', pax.firstName);
    data.append('Paxes[' + index + '].LastName', pax.lastName);
    data.append('Paxes[' + index + '].Phone', pax.phone);
    data.append('Paxes[' + index + '].Email', pax.email);
    data.append('Paxes[' + index + '].DOB', getStrDate(pax.birthDate));
    data.append('Paxes[' + index + '].PaxCode', pax.paxCode.toString());
    data.append('Paxes[' + index + '].PaxTypeCode', pax.typeDescription);
    data.append('Paxes[' + index + '].Param_1', pax.additionalInfo);

    pax.suppliments.forEach((sup, supIndex) => {
      const append = (key: string, value: string) => {
        data.append('Paxes[' + index + '].Suppliments[' + supIndex + '].' + key, value);
      };

      append('Price', sup.price.toString());
      append('GenId', sup.genId.toString());
      append('AgeFrom', sup.ageFrom.toString());
      append('AgeTo', sup.ageTo.toString());
      append('SupplimentID', sup.supplimentID.toString());
      append('Type', sup.type);
      append('Require', sup.require.toString());
      append('Include', sup.include.toString());
      append('Shift', sup.shift.toString());
      append('Duration', sup.duration.toString());
      append('Remark', sup.remark);
      append('Name', sup.name);
      append('NameLoc', sup.nameLoc);
      append('ApplyCode', sup.applyCode);
      append('Supplier', sup.supplier);
      append('SupplierCC', sup.supplierCC);
      append('Service', sup.service);
      append('IsTranserService', sup.isTranserService.toString());

      if (JSON.stringify(sup).includes('KSML')) {
        append('IsPicked', sup.radioValue === '1' ? 'true' : 'false');
      } else {
        append('IsPicked', sup.checked.toString());
      }
    });
  });

  return data;
};
