import { Fragment, useEffect, useState } from 'react';
import { getSkiCities, getSkiDates, getSkiResults } from '../services/searchService';
import { useParams } from 'react-router-dom';
import { Package } from '../ts/interfaces/Package';
import { AutocompleteItem } from '../ts/interfaces/Generic';
import MainCarousel from '../components/Main/MainCarousel';
import ResultsListSkeleton from '../components/Skeleton/Results/ResultsListSkeleton';
import ResultSkiCard from '../components/Result/ResultSkiCard';
import Sorter from '../components/Result/Sorter';
import { SorterBy, SorterModel, SorterOrder } from '../ts/interfaces/Sorter';
import TableViewHeader from '../components/Result/TableViewHeader';
import { SkiDate } from '../ts/interfaces/Ski';
import Filter from '../components/Result/Filter';
import { FilterModel } from '../ts/classes/FilterModel';
import { changeDateFormatToSlash } from '../services/utilService';
import { useGetPage } from '../hooks/useGetPage';

const SearchSkiResultPage = () => {
  const [skies, setSkies] = useState<Package[]>();
  const [country, setCountry] = useState('');
  const [date, setDate] = useState('');
  const [allCities, setAllCities] = useState<AutocompleteItem[]>([]);
  const [filter, setFilter] = useState<FilterModel>();
  const params = useParams();
  const page = useGetPage();

  useEffect(() => {
    (async () => {
      const newAllCities = await getSkiCities();
      setAllCities(newAllCities);
      setCountry(newAllCities.find((location) => location.value === params.city)?.label!);

      const storedDates = sessionStorage.getItem('skiDates');
      const newAllDates: SkiDate[] = !storedDates
        ? await getSkiDates(params.city!)
        : JSON.parse(storedDates);
      setDate(newAllDates.find((date) => date.Date === params.date)?.Date! || 'כל התאריכים');

      const newSkies = await getSkiResults({ city: params.city!, date: params.date! });
      setSkies(newSkies);

      const newFilter = new FilterModel(
        newSkies,
        newAllCities.map((c) => c.label).slice(1),
        undefined,
        true,
        newAllCities,
      );

      setFilter(newFilter);
    })();
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    setSkies(undefined);
    setFilter(undefined);
  };

  const onFilterChange = (filter: FilterModel) => {
    setFilter(filter);

    const newSkies = skies?.map((resert) => {
      const filterByNights = filter.nights.some((item) => item.checked);
      const filterByPackage = filter.packages.some((item) => item.checked);
      const filterByRating = filter.ratings?.some((item) => item.checked);
      const filterByHotel = !!filter.selectedHotel;

      const priceFiltered =
        filter.prices.value[0] <= resert.Price.OnePersonPrice &&
        resert.Price.OnePersonPrice <= filter.prices.value[1];
      const nightsFiltered = filterByNights
        ? filter.nights.find((item) => item.count === resert.NightsCount)?.checked
        : true;
      const packagesFiltered = filterByPackage
        ? filter.packages.find((item) => item.name === resert.BoardDescription)?.checked
        : true;

      const ratingsFiltered = filterByRating
        ? filter.ratings?.find((item) => item.count === resert.Stars)?.checked
        : true;
      const hotelFiltered = filterByHotel ? filter.selectedHotel === resert.HotelName : true;

      let countryFiltered = true;
      if (params.city === 'all') {
        const label = allCities.find((city) => city.value === resert.City)!?.label;
        countryFiltered = filter.selectedCountries.length
          ? filter.selectedCountries.includes(label)
          : true;
      }

      let dateFiltered = true;
      if (params.date === 'all') {
        dateFiltered = filter.selectedMonths.length
          ? filter.selectedMonths.includes(resert.UrlFriendlyCheckIn)
          : true;
      }

      const visible =
        priceFiltered &&
        nightsFiltered &&
        packagesFiltered &&
        hotelFiltered &&
        countryFiltered &&
        ratingsFiltered &&
        dateFiltered;

      return { ...resert, visible: !!visible };
    });

    setSkies(newSkies);
  };

  const onFilterReset = () => {
    const newSkies = skies?.map((resert) => ({ ...resert, visible: true }));

    setFilter(
      new FilterModel(skies!, allCities.map((c) => c.label).slice(1), undefined, true, allCities),
    );

    setSkies(newSkies);
  };

  const onSorterChange = (sorter: SorterModel) => {
    const newSkies = skies?.sort((a, b) => {
      if (sorter.by === SorterBy.Name) {
        if (sorter.order === SorterOrder.Asc) {
          return a.HotelName.localeCompare(b.HotelName);
        } else {
          return b.HotelName.localeCompare(a.HotelName);
        }
      } else {
        if (sorter.order === SorterOrder.Asc) {
          return a.Price.OnePersonPrice - b.Price.OnePersonPrice;
        } else {
          return b.Price.OnePersonPrice - a.Price.OnePersonPrice;
        }
      }
    });

    setSkies([...newSkies!]);
  };

  return (
    <Fragment>
      <MainCarousel
        onSubmit={onSubmit}
        className="main-carousel-short"
        listOfGalleryId={page?.Carousel?.ListOfGalleryId || []}
      />

      {skies ? (
        <Fragment>
          <div className="results-header">
            <div className="results-title">
              <h2>טיולים ב {country}</h2>
              <div className="flex-vertical-center">
                <span>{`${skies.length} אפשרויות  · ${changeDateFormatToSlash(date)}`}</span>
              </div>
            </div>
            <Sorter onChange={onSorterChange} />
          </div>

          <div className="results-main">
            <div className="results-filter">
              {!!filter && (
                <Filter filter={filter} onChange={onFilterChange} onReset={onFilterReset} />
              )}
            </div>
            <div className="results-list">
              <TableViewHeader />
              {skies
                .filter((resert) => resert.visible)
                .map((resert) => (
                  <ResultSkiCard resert={resert} key={resert.index} />
                ))}
            </div>
          </div>
        </Fragment>
      ) : (
        <ResultsListSkeleton />
      )}
    </Fragment>
  );
};

export default SearchSkiResultPage;
