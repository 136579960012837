import React, { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDetailsParams } from '../services/packageService';
import { PackageDetails } from '../ts/interfaces/PackageDetails';
import { faPlaneArrival, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import FlightCard from '../components/Details/FlightCard';
import SummaryCard from '../components/Details/SummaryCard';
import CarouselCard from '../components/Details/CarouselCard';
import InformationCard from '../components/Details/ItineraryCard';
import IncludeCard from '../components/Details/IncludeCard';
import RoomsCard from '../components/Details/RoomsCard';
import DetailsSkeleton from '../components/Skeleton/DetailsSkeleton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getDetails } from '../services/skiService';

const DetailsSkiPage: FC = () => {
  const params = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [details, setDetails] = useState<PackageDetails | undefined>();

  useEffect(() => {
    (async () => {
      try {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha('details');
        if (!token) {
          console.log('No token');
          return;
        }
      } catch (err) {}

      const detailsParams: getDetailsParams = {
        checkIn: params.checkIn!,
        city: params.city!,
        dealId: params.dealId!,
        hotelName: params.hotelName!,
        nights: params.nights!,
        packageId: params.packageId!,
        room: params.room!,
        isSale: params.isSale!,
      };

      const newDetails = await getDetails(detailsParams);
      localStorage.setItem('details', JSON.stringify(newDetails));
      setDetails(newDetails);
    })();
  }, [params, executeRecaptcha]);

  return (
    <Fragment>
      {details && !details.HasErrors && (
        <div className="details-wrapper">
          <div className="details-first">
            <div className="details-center">
              <CarouselCard details={details} />
            </div>
            <div className="details-sidebar">
              <SummaryCard details={details} />
            </div>
          </div>

          <div className="details-title">מידע על הטיסה </div>
          <FlightCard
            flight={details.Flight.Departure}
            returnFlight={details.Flight.Arrival}
            icon={faPlaneDeparture}
            title="יציאה"
          />
          <FlightCard
            flight={details.Flight.ReturnDeparture}
            returnFlight={details.Flight.ReturnArrival}
            icon={faPlaneArrival}
            title="חזרה"
          />

          <InformationCard itinerary={details.Itinerary} />

          <IncludeCard include={details.IncludeInfo} />

          <div className="details-title">בחר חדרים</div>
          <RoomsCard roomsInfo={details.Rooms} url="book/ski" />
        </div>
      )}

      {details && details.HasErrors && <span>טיול לא נמצא, נסה אחר</span>}

      {!details && <DetailsSkeleton />}
    </Fragment>
  );
};

export default DetailsSkiPage;
