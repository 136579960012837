import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

interface SearchHeadProps {
  active: 'tour' | 'ski';
  onChangeActive: (value: 'tour' | 'ski') => void;
}

const SearchHead: FC<SearchHeadProps> = ({ active, onChangeActive }) => {
  const location = useLocation();

  return (
    <Fragment>
      {location.pathname === '/' && (
        <div className="search-head">
          <div
            className={'search-head-item ' + (active === 'tour' ? 'active' : '')}
            onClick={() => onChangeActive('tour')}
          >
            טיולים מאורגנים
          </div>
          <div
            className={'search-head-item ' + (active === 'ski' ? 'active' : '')}
            onClick={() => onChangeActive('ski')}
          >
            סקי
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchHead;
