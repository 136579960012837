import { faCalendar, faMoon, faUser, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, SyntheticEvent } from 'react';
import { Package } from '../../ts/interfaces/Package';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../services/utilService';

interface ResultCardProps {
  resert: Package;
}

const ResultCard: FC<ResultCardProps> = ({ resert }) => {
  const navigate = useNavigate();

  const onImageLoadError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = require('../../assets/images/sky.jpg');
  };

  const goToDetails = () => {
    navigate(
      `/details/${resert.UrlFriendlyHotelName}/${resert.City}/${resert.UrlFriendlyCheckIn}/${resert.NightsCount}/${resert.Id}/${resert.PackageId}/${resert.Room}`,
    );
  };
  return (
    <div className="results-card">
      <div className="results-card-img">
        <img src={resert.MainPhoto.Link} alt={resert.MainPhoto.Alt} onError={onImageLoadError} />
      </div>
      <div className="results-card-content">
        <div className="results-card-title">{resert.HotelName}</div>
        <div className="results-card-facilities">
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faCalendar} />
            {resert.CheckIn} - {resert.CheckOut}
          </div>
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faUtensils} />
            {resert.BoardDescription}
          </div>
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faMoon} />
            {resert.NightsCount} לילות
          </div>
          {resert.GuideName && (
            <div className="results-card-facilities-item">
              <FontAwesomeIcon icon={faUser} />
              {resert.GuideName}
            </div>
          )}
        </div>
      </div>
      <div className="results-card-footer">
        <div className="results-card-price">
          <span className="price">
            <span className="title">מחיר לאדם בחדר / </span>
            <span>{formatPrice(resert.Price.OnePersonPrice, resert.Price.CurrencySymbol)}</span>
          </span>
          <span className="price-sm">
            <span className="title-sm">סה"כ / </span>
            <span>{formatPrice(resert.Price.TotalPrice, resert.Price.CurrencySymbol)}</span>
          </span>
        </div>
        <div className="button-wr">
          <button className="button-st button-colored" onClick={goToDetails}>
            הזמן
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
