import { FC } from 'react';

interface PageContentProps {
  pageContent?: any[];
  order: number;
}
export const PageContent: FC<PageContentProps> = ({ pageContent, order }) => {
  return (
    <div style={{ order }} className="page-content">
      {pageContent
        ?.filter((content) => content.Body)
        .map((content, index) => (
          <div
            className="main-text"
            key={index}
            dangerouslySetInnerHTML={{ __html: content.Body }}
          />
        ))}
    </div>
  );
};

export default PageContent;
