export class PassengerInfoModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  notes: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.notes = '';

    // this.firstName = 'test';
    // this.lastName = 'test';
    // this.email = 'test@dd.dd';
    // this.phone = '1234567890';
    // this.notes = 'test';
  }
}
