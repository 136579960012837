import { AutocompleteItem, SearchParams } from '../ts/interfaces/Generic';
import { serverPath } from '../constants/path';
import { Package } from '../ts/interfaces/Package';
import { SkiDate } from '../ts/interfaces/Ski';

type SkiSearchRequestParams = {
  city: string;
  date: string;
};

export const getCountries = async (): Promise<AutocompleteItem[]> => {
  try {
    const countries = sessionStorage.getItem('countries');
    if (countries) {
      return JSON.parse(countries);
    } else {
      const response = await (await fetch(serverPath + 'gettourcountries')).json();
      let result: AutocompleteItem[] = response.map((item: any) => ({
        value: item.Code,
        label: item.Name,
      }));
      result.sort((a, b) => (a.label < b.label ? -1 : 1));
      result.unshift({ value: 'all', label: 'את כל' });
      sessionStorage.setItem('countries', JSON.stringify(result));
      return result;
    }
  } catch (err) {
    return [];
  }
};

export const getMonths = async (location: string): Promise<AutocompleteItem[]> => {
  try {
    const response = await (await fetch(serverPath + 'gettourmonths/' + location)).json();
    let result = response.map((item: any) => ({
      value: item.Date,
      label: item.Name,
    }));
    result.unshift({ value: 'all', label: 'את כל' });
    sessionStorage.setItem('months', JSON.stringify(result));
    return result;
  } catch (err) {
    return [];
  }
};

export const getResults = async ({ location, month }: SearchParams): Promise<Package[]> => {
  try {
    let response: Package[] = await (
      await fetch(`${serverPath}search/${location}/${month}/tour`)
    ).json();

    response.forEach((item, index) => {
      item.visible = true;
      item.index = index + '';
    });

    response.sort((a, b) => {
      const getDate = (date: string) =>
        new Date(
          parseFloat(date.split('-')[2]),
          parseFloat(date.split('-')[1]) - 1,
          parseFloat(date.split('-')[0]),
        ).getTime();

      return getDate(a.UrlFriendlyCheckIn) - getDate(b.UrlFriendlyCheckIn);
    });

    return response;
  } catch (err) {
    return [];
  }
};

export const getSkiCities = async (): Promise<AutocompleteItem[]> => {
  try {
    const skiCities = sessionStorage.getItem('skiCities');
    if (skiCities) {
      return JSON.parse(skiCities);
    } else {
      const response = await (await fetch(serverPath + 'skidestinations')).json();
      let result: AutocompleteItem[] = response.ListOfSkiDestinations.map((item: any) => ({
        value: item.CityCode,
        label: item.Name,
      }));
      result.sort((a, b) => (a.label < b.label ? -1 : 1));
      sessionStorage.setItem('skiCities', JSON.stringify(result));
      return result;
    }
  } catch (err) {
    return [];
  }
};

export const getSkiDates = async (cityName: string): Promise<SkiDate[]> => {
  try {
    const response = (await (await fetch(serverPath + 'skidates/' + cityName)).json()).SkiDates;
    const result = response.map((item: SkiDate) => {
      const arrDate = item.Date.split('-');
      return { Date: [arrDate[0], arrDate[2], arrDate[1]].join('-') };
    });

    sessionStorage.setItem('skiDates', JSON.stringify(result));
    return result;
  } catch (err) {
    return [];
  }
};

export const getSkiResults = async ({ city, date }: SkiSearchRequestParams): Promise<Package[]> => {
  try {
    let response: Package[] = await (await fetch(`${serverPath}searchski/${city}/${date}`)).json();

    response.forEach((item, index) => {
      item.visible = true;
      item.index = index + '';
    });

    response.sort((a, b) => {
      const getDate = (date: string) =>
        new Date(
          parseFloat(date.split('-')[2]),
          parseFloat(date.split('-')[1]) - 1,
          parseFloat(date.split('-')[0]),
        ).getTime();

      return getDate(a.UrlFriendlyCheckIn) - getDate(b.UrlFriendlyCheckIn);
    });

    return response;
  } catch (err) {
    return [];
  }
};
