import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="not-found">
      <span>עמוד זה אינו מוגדר</span>
    </div>
  );
};

export default NotFoundPage;
