import React, { FC, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { formatDateStrToStr, formatPaxType } from '../../services/utilService';
import { RoomModel } from '../../ts/classes/RoomModel';
import Suppliments from '../Book/Suppliments';
import DisplayGroup from '../Ui/DisplayGroup';
import { PackageDetails } from '../../ts/interfaces/PackageDetails';

const PassengersListClosedSuccess: FC = () => {
  const rooms: RoomModel[] = JSON.parse(localStorage.getItem('rooms')!);
  const details: PackageDetails = JSON.parse(localStorage.getItem('details')!);

  return (
    <Fragment>
      {rooms.map((room, roomIndex) => (
        <Fragment key={roomIndex}>
          <div className="book-passenger-header">
            {room.paxes.map((_pax, paxIndex) => (
              <Fragment key={paxIndex}>
                <FontAwesomeIcon icon={faUser} />{' '}
              </Fragment>
            ))}
            {room.description}
            <div className="text-bold">{room.classDescription}</div>
          </div>
          <div className="form-wrapper">
            {room.paxes.map((pax, paxIndex) => (
              <div key={paxIndex} className="book-passenger-pax form-wrapper">
                <div className="col-12 mb-10 text-bold">
                  <FontAwesomeIcon icon={faUser} /> {formatPaxType(pax.typeDescription)}{' '}
                  {paxIndex + 1}
                </div>
                <DisplayGroup value={pax.firstName} label="שם פרטי" className="col-12 col-md-6" />
                <DisplayGroup value={pax.lastName} label="שם משפחה" className="col-12 col-md-6" />
                <DisplayGroup
                  value={formatDateStrToStr(pax.birthDate as Date)}
                  label="תאריך לידה"
                  className="col-12 col-md-6"
                />
                <div style={{ flex: '0 0 50%' }}></div>
                <DisplayGroup
                  value={pax.passportNumber}
                  label="מספר דרכון"
                  className="col-12 col-md-6"
                />
                <DisplayGroup
                  value={formatDateStrToStr(pax.passportExpirationDate as Date)}
                  label="תוקף דרכון"
                  className="col-12 col-md-6"
                />
                <DisplayGroup value={pax.nationality} label="אזרחות" className="col-12 col-md-6" />

                {paxIndex === 0 && roomIndex === 0 && (
                  <Fragment>
                    <DisplayGroup value={pax.email} label='דוא"ל' className="col-12 col-md-6" />
                    <DisplayGroup value={pax.phone} label="טלפון" className="col-12 col-md-6" />
                  </Fragment>
                )}
                {details.VisaRequired && (
                  <div className="book-passenger-visa form-wrapper">
                    <div className="book-passenger-visa-header">VISA</div>
                    <DisplayGroup
                      value={pax.fathersName}
                      label="שמו של האב"
                      className="col-12 col-md-6"
                    />
                    <DisplayGroup
                      value={pax.mothersName}
                      label="שם האם"
                      className="col-12 col-md-6"
                    />
                    <DisplayGroup
                      value={pax.birthCountry}
                      label="ארץ לידה"
                      className="col-12 col-md-6"
                    />
                  </div>
                )}

                <Suppliments
                  pax={{ ...pax, suppliments: pax.suppliments.filter((sup) => sup.checked) }}
                  paxIndex={paxIndex}
                  roomIndex={roomIndex}
                  rooms={rooms}
                  currencySymbol={details.Rooms[0].CurrencySymbol}
                  onChange={() => {}}
                  disabled={true}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default PassengersListClosedSuccess;
