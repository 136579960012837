import { FC, useEffect, useState } from 'react';
import { getCountries, getMonths } from '../../services/searchService';
import { AutocompleteItem, SearchParams } from '../../ts/interfaces/Generic';
import { useNavigate, useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useGetPage } from '../../hooks/useGetPage';

import { faCalendar, faLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchSelect from './SearchSelect';
import { setButtonClasses } from '../../services/utilService';

interface SearchTourProps {
  onSubmit: () => void;
}

export const SearchTour: FC<SearchTourProps> = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    location: '',
    month: '',
  });
  const [months, setMonths] = useState<AutocompleteItem[]>([]);
  const [countries, setCountries] = useState<AutocompleteItem[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams();
  const page = useGetPage();

  useEffect(() => {
    if (
      page?.Page.Country?.Code &&
      countries.some((country) => page?.Page.Country?.Code === country.value)
    ) {
      setSearchParams({ ...searchParams, location: page.Page.Country.Code });
      getMonthsByLocation(page.Page.Country.Code);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const countries = await getCountries();
      setCountries(countries);
      if (params.location && params.month) {
        const storedMonths = sessionStorage.getItem('months');
        const months = !storedMonths ? await getMonths(params.location) : JSON.parse(storedMonths);
        setMonths(months);
        setSearchParams({ month: params.month, location: params.location });
      } else {
        setSearchParams({ ...searchParams, location: countries[0].value });
        getMonthsByLocation(countries[0].value);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMonthsByLocation = async (location: string) => {
    const months = await getMonths(location);
    setMonths(months);

    setSearchParams({ location, month: months[0]?.value });
  };

  const onSelectLocation = async (location: string) => {
    setSearchParams({ ...searchParams, location });
    setMonths([]);
    getMonthsByLocation(location);
  };

  const onSubmit = async () => {
    if (searchParams.location && searchParams.month && !isDisabled) {
      try {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const token = await executeRecaptcha('search');
        if (!token) {
          console.log('No token');
          return;
        }
      } catch (err) {}
      navigate(`/tour/results/${searchParams.location}/${searchParams.month}`);
      props.onSubmit();
      setIsDisabled(true);
      setTimeout(() => setIsDisabled(false), 1000);
    }
  };
  return (
    <div className="search-main">
      <SearchSelect
        data={countries}
        value={searchParams.location}
        icon={faLocationDot}
        onSelect={onSelectLocation}
        subText="לאן אתה הולך?"
      />

      <SearchSelect
        data={months}
        value={searchParams.month}
        icon={faCalendar}
        onSelect={(month) => setSearchParams({ ...searchParams, month })}
        subText="בחר תאריך"
        lastElement={true}
      />

      <div
        className={setButtonClasses(
          'search-main-button',
          !searchParams.location || !searchParams.month || isDisabled,
        )}
        onClick={onSubmit}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </div>
    </div>
  );
};

export default SearchTour;
