import { serverPath } from '../constants/path';
import { Sale } from '../ts/interfaces/Sale';
import { Page } from '../ts/interfaces/Page';
import { ContactUsModel } from '../ts/classes/ContactUsModel';
import { SignInModel } from '../ts/classes/SignInModel';

export const getPage = async (path: string): Promise<Page> => {
  const url = 'getpage/' + path + '/tour';
  let response = await (await fetch(serverPath + url)).json();
  return response;
};
export const getSkiPage = async (path: string): Promise<Page> => {
  const url = 'getpage/' + path + '/ski';
  let response = await (await fetch(serverPath + url)).json();
  return response;
};

export const getSales = async (pageName: string, type?: 'ski' | 'tour'): Promise<Sale[]> => {
  const url = `getsales/${pageName}/${type}`;

  return (await (await fetch(serverPath + url)).json()).Sales || [];
};

export const contactUs = async (data: ContactUsModel): Promise<void> => {
  const url = 'contact';
  const formData = new FormData();
  formData.append('Name', data.name);
  formData.append('Phone', data.phone);
  formData.append('Email', data.email);
  formData.append('Message', data.message);
  formData.append('ConfirmPromotions', true.toString());
  const params = { method: 'POST', body: formData };

  await (await fetch(serverPath + url, params)).json();
};

export const getAbout = async (): Promise<string> => {
  const url = 'about/tour';

  const response: any = await (await fetch(serverPath + url)).json();

  return response.Page.Languages[0].Title.Body;
};

export const getInfo = async (page: string): Promise<string> => {
  const url = `pages/info/${page}/tour`;

  const response: any = await (await fetch(serverPath + url)).json();

  return response.Page.Languages[0].Title.Body;
};

export const getLanding = async (pageName: string): Promise<any> => {
  const url = `getlandingpage/${pageName}`;

  return await (await fetch(serverPath + url)).json();
};

export const signIn = async (credentails: SignInModel): Promise<any> => {
  const url = `account/signin`;

  const formData = new FormData();
  formData.append('User', credentails.username);
  formData.append('Password', credentails.password);
  const params = { method: 'POST', body: formData };

  return await (await fetch(serverPath + url, params)).json();
};

export const getUserInfo = async (): Promise<any> => {
  const url = 'account/personalinformation';

  const token = sessionStorage.getItem('token');
  if (token) {
    const params = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    return await (await fetch(serverPath + url, params)).json();
  }
};

export const logOut = async (): Promise<any> => {
  const url = `account/signout`;

  const token = sessionStorage.getItem('token');
  if (token) {
    const params = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };

    return await (await fetch(serverPath + url, params)).json();
  }
};
