import React, { useCallback, useState } from 'react';
import { FC } from 'react';
import { ValidationBody } from '../../ts/interfaces/Validation';
import { getErrorInField } from '../../services/validation';

interface FormGroupProps {
  onChange: (item: string) => void;
  className?: string;
  value: string;
  label: string;
  validation?: ValidationBody;
  isSubmitted?: boolean;
  disabled?: boolean;
  type?: string;
  allowOnlyNumbers?: boolean;
}

const FormGroup: FC<FormGroupProps> = (props) => {
  const [value, setValue] = useState(props.value);

  const isValid = useCallback(() => {
    return getErrorInField(props.validation!, value, props.isSubmitted!);
  }, [value, props.isSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.className}>
      <div className="form-group">
        <label>
          {props.label} {props.validation?.required && <span className="danger-color">*</span>}
        </label>
        <input
          className={'form-control ' + (isValid() ? 'is-invalid' : '')}
          value={props.value}
          onChange={(event) => {
            if (props.allowOnlyNumbers) {
              if (event.target.value.match(/^[0-9]*$/)) {
                setValue(event.target.value);
                props.onChange(event.target.value);
              }
            } else {
              setValue(event.target.value);
              props.onChange(event.target.value);
            }
          }}
          disabled={props.disabled}
          type={props.type}
        />
        {!!isValid() && <div className="form-invalid-text">{isValid()}</div>}
      </div>
    </div>
  );
};

FormGroup.defaultProps = {
  validation: {},
  isSubmitted: false,
  className: '',
};

export default FormGroup;
