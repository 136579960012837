import {
  faCalendar,
  faCar,
  faMoon,
  faSnowflake,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, Fragment, SyntheticEvent } from 'react';
import { Package } from '../../ts/interfaces/Package';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../services/utilService';
import { Rating } from '@mui/material';
import Gallery from '../Ui/Gallery';

interface ResultSkiCardProps {
  resert: Package;
}

const ResultSkiCard: FC<ResultSkiCardProps> = ({ resert }) => {
  const navigate = useNavigate();

  const onImageLoadError = ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = require('../../assets/images/sky.jpg');
  };

  const goToDetails = () => {
    const dateArr = resert.UrlFriendlyCheckIn.split('-');
    const date = [dateArr[2], dateArr[0], dateArr[1]].join('-');
    navigate(
      `/details/ski/${resert.UrlFriendlyHotelName}/${resert.City}/${date}/${resert.NightsCount}/${resert.Id}/${resert.PackageId}/${resert.Room}`,
    );
  };
  return (
    <div className="results-card results-card-ski">
      <div className="results-card-title mobile">
        <span>{resert.HotelName}</span>
        <div className="results-card-rating">
          <Rating value={resert.Stars} readOnly />
        </div>
      </div>
      <div className="results-card-img">
        <img src={resert.MainPhoto.Link} alt={resert.MainPhoto.Alt} onError={onImageLoadError} />
        <Gallery images={resert.AllPhotos} />
      </div>
      <div className="results-card-content">
        <div className="results-card-title desktop">
          <span>{resert.HotelName}</span>
          <div className="results-card-rating">
            <Rating value={resert.Stars} readOnly />
          </div>
        </div>
        <div className="results-card-facilities">
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faCalendar} />
            {resert.CheckIn} - {resert.CheckOut}
          </div>
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faUtensils} />
            {resert.BoardDescription}
          </div>
          <div className="results-card-facilities-item">
            <FontAwesomeIcon icon={faMoon} />
            {resert.NightsCount} לילות
          </div>
          {resert.Suppliments.map((suppliment) => (
            <div className="results-card-facilities-item long">
              <Fragment>
                <FontAwesomeIcon
                  icon={suppliment.Name.toLowerCase().includes('transfer') ? faCar : faSnowflake}
                />
                {suppliment.NameLoc || suppliment.Name}
              </Fragment>
            </div>
          ))}
        </div>
      </div>
      <div className="results-card-footer">
        <div className="results-card-price">
          <span className="price">
            <span className="title">מחיר לאדם בחדר / </span>
            <span>{formatPrice(resert.Price.OnePersonPrice, resert.Price.CurrencySymbol)}</span>
          </span>
          <span className="price-sm">
            <span className="title-sm">סה"כ / </span>
            <span>{formatPrice(resert.Price.TotalPrice, resert.Price.CurrencySymbol)}</span>
          </span>
        </div>
        <div className="button-wr">
          <button className="button-st button-colored" onClick={goToDetails}>
            הזמן
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultSkiCard;
