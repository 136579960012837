import React, { useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

export const withSnackbar = (WrappedComponent: any) => {
  return (props: any) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("I'm a custom snackbar");
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState<AlertColor>('success'); /** error | warning | info */

    const showMessage = (message: string, severity = 'success' as AlertColor, duration = 2000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const handleClose = (_: any) => {
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
        >
          <Alert variant="filled" onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};
