import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { sitePath } from '../../constants/path';

interface SibleSaleProps {
  tab: any;
  image: string;
}

const SinlgeSale: FC<SibleSaleProps> = ({ tab, image }) => {
  const navigate = useNavigate();

  const navigateToDetails = () => {
    const url = tab.URL.slice(tab.URL.indexOf('co.il/') + 5, tab.URL.length);
    navigate(url);
  };

  return (
    <div className="single-sale">
      <div className="sales-card-wr">
        <div className="details-title">{tab.Tab}</div>
        <div className="sales-card">
          <div className="image">
            <img src={sitePath + image} alt={''} />
            <div className="hotel-name">{tab.Desc}</div>
          </div>
          <div className="sales-card-items" dangerouslySetInnerHTML={{ __html: tab.Body }}></div>
          <div className="sales-card-footer">
            <button className="button-st button-colored" onClick={navigateToDetails}>
              פרטים
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinlgeSale;
