import { Skeleton } from '@mui/material';

const PaymentSkeleton = () => {
  return (
    <div className="pay-wrapper">
      <Skeleton
        variant="rectangular"
        width="100%"
        height="38px"
        style={{ borderRadius: 6, marginBottom: 12 }}
      />
      <div className="pay-2">
        <Skeleton variant="rectangular" width="49%" height="100%" />
        <Skeleton variant="rectangular" width="49%" height="100%" />
      </div>
      <div className="pay-3">
        <Skeleton
          variant="rectangular"
          height="100%"
          style={{ borderRadius: 6, marginBottom: 12 }}
        />
      </div>
      <div className="pay-2 pay-21">
        <Skeleton variant="rectangular" width="49%" height="100%" />
        <Skeleton variant="rectangular" width="49%" height="100%" />
      </div>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="38px"
        style={{ borderRadius: 6, marginBottom: 45 }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="56px"
        style={{ borderRadius: 6, marginBottom: 5 }}
      />
    </div>
  );
};

export default PaymentSkeleton;
