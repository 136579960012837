import { useEffect, useState } from 'react';
import { Page } from '../ts/interfaces/Page';
import { useLocation } from 'react-router-dom';

export const useGetPage = () => {
  const [page, setPage] = useState<Page>();
  const location = useLocation();

  const getPage = () => {
    let count = 120;
    const getInterval = setInterval(() => {
      const pageStr = localStorage.getItem('page');
      if (pageStr) {
        setPage(JSON.parse(pageStr));
        clearInterval(getInterval);
      } else if (!count) {
        clearInterval(getInterval);
      } else {
        count -= 1;
      }
    }, 200);
  };

  useEffect(getPage, []);

  useEffect(() => {
    if (
      location.pathname.includes('tour') ||
      location.pathname.includes('ski') ||
      location.pathname === '/'
    ) {
      getPage();
    }
  }, [location.pathname]);

  return page;
};
